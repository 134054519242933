import { OptionsType } from "react-select";
import { MarkerProps } from "../../asset/components/GeoCordsModal";
import { Position } from "../Position";
import { Telemetry } from "../Telemetry";
import { UseCase } from "../UseCase";
import { AssetGroup } from "./AssetGroup";

export interface AssetById {
  asset: IAsset | null;
}

export interface AssetPage {
  assets: IAsset[];
  totalPages: number;
  assetCount: number;
}

export interface RangeBounds {
  lower: number | null;
  upper: number | null;
}

export interface IAsset {
  id: string;
  name: string;
  icon: string;
  telemetry?: Telemetry;
  lastLocation: string | null;
  description: string;
  tags: ITag[];
  lastSeen: string | null;
  assetGroups?: AssetGroup[];
  useCases?: UseCase[];
  allowedTemperatureRange?: RangeBounds;
  lastPosition?: Position;
  technicalRoomNumber?: string;
  inventoryNumber?: string;
}

export interface AssetFormData {
  name: string;
  inventoryNumber?: string;
  tags: { name: string; id: string }[];
  description?: string;
  assetGroups: { name: string; id: string }[];
  useCaseIds: string[];
  buildingparts?: OptionsType<{ label: string; value: string }>;
  geocords?: MarkerProps | undefined;
  allowedTemperatureRange?: RangeBounds | undefined;
}

export interface ITag {
  __typename: string;
  id: string;
  name: string;
  battery?: number | null;
}

export function instanceOfIBeacon(object: ITag): object is IBeacon {
  if ("uuid" in object && (object as IBeacon).mac) {
    return true;
  } else return false;
}

export function instanceOfEddystone(object: ITag): object is EddystoneBeacon {
  if ("nId" in object && (object as EddystoneBeacon).mac) {
    return true;
  } else return false;
}

export interface EddystoneBeacon extends ITag {
  mac: string;
  nId: string | null;
  bId: string | null;
  txPower: number;
  advInterval: number;
}

export interface IBeacon extends ITag {
  mac: string;
  uuid: string;
  majorId: number;
  minorId: number;
  txPower: number;
  advInterval: number;
}
