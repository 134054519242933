import React, {useEffect, useState} from "react";
import {Form, FormGroup, Input, InputGroup, InputGroupText} from "reactstrap";
import {ActiveAssets, ActiveStatus} from "../../models/ActiveStatus";
import ChronoUnit from "../../models/ChronoUnit";
import {ActiveFilter} from "../../models/ActiveFilter";

function ActiveAssetsFilter({
                              onChange,
                              defaultChecked,
                            }: ActiveAssetsFilterProps) {
  const defaultState = {
    status: ActiveStatus.DEACTIVE,
    period: {
      interval: 15,
      unit: ChronoUnit.MINUTES,
    },
  };
  const [current, setCurrent] = useState<ActiveAssets>(defaultState);

  const statusOptions = [
    {
      label: "Deaktiviert",
      value: ActiveStatus.DEACTIVE,
    },
    {
      label: "Aktive Assets",
      value: ActiveStatus.ACTIVE,
    },
    {
      label: "Inaktive Assets",
      value: ActiveStatus.INACTIVE,
    },
  ];

  const periodOptions = [
    {
      label: "15 Minuten",
      value: {
        interval: 15,
        unit: ChronoUnit.MINUTES,
      },
    },
    {
      label: "1 Stunde",
      value: {
        interval: 1,
        unit: ChronoUnit.HOURS,
      },
    },
    {
      label: "1 Tag",
      value: {
        interval: 1,
        unit: ChronoUnit.DAYS,
      },
    },
    {
      label: "1 Woche",
      value: {
        interval: 1,
        unit: ChronoUnit.WEEKS,
      },
    },
    {
      label: "1 Monat",
      value: {
        interval: 1,
        unit: ChronoUnit.MONTHS,
      },
    },
  ];

  useEffect(() => {
    if (defaultChecked) {
      setCurrent(defaultChecked);
    }
  }, [defaultChecked]);

  function handleClick(newStatus: ActiveStatus) {
    switch (newStatus) {
      case ActiveStatus.ACTIVE: {
        const newState = {
          status: ActiveStatus.ACTIVE,
          period: current.period,
        };
        onChange(newState);
        setCurrent(newState);
        break;
      }

      case ActiveStatus.INACTIVE: {
        const newState = {
          status: ActiveStatus.INACTIVE,
          period: current.period,
        };
        onChange(newState);
        setCurrent(newState);
        break;
      }

      case ActiveStatus.DEACTIVE: {
        onChange(undefined);
        setCurrent(defaultState);
        break;
      }
    }
  }

  return (
    <Form className={"d-flex"}>
      <FormGroup>
        <InputGroup>
          <InputGroupText>Filter</InputGroupText>
          <Input
            className="form-select bg-white"
            type="select"
            onChange={(e) =>
              handleClick(
                ActiveStatus[statusOptions[Number(e.target.value)].value]
              )
            }
            value={statusOptions.findIndex(
              (opt) => opt.value === current.status
            )}
          >
            {statusOptions.map((option, index) => (
              <option key={option.value} value={index}>
                {option.label}
              </option>
            ))}
          </Input>
        </InputGroup>
      </FormGroup>
      <FormGroup className={"ms-3"}>
        <InputGroup>
          <InputGroupText>Zeitraum</InputGroupText>
          <Input
            className="form-select bg-white"
            type="select"
            onChange={(e) => {
              const newState = {
                ...current,
                period: {
                  ...periodOptions[Number(e.target.value)].value,
                },
              };
              onChange(newState);
              setCurrent(newState);
            }}
            value={periodOptions.findIndex(
              (opt) =>
                opt.value.unit === current.period.unit &&
                opt.value.interval === current.period.interval
            )}
            disabled={current.status === ActiveStatus.DEACTIVE}
          >
            {periodOptions.map((option, index) => (
              <option key={index} value={index}>
                {option.label}
              </option>
            ))}
          </Input>
        </InputGroup>
      </FormGroup>
    </Form>
  );
}

export function useActiveAssets(
  activeFilter?: ActiveFilter
): ActiveAssets | undefined {
  if (activeFilter === undefined) return undefined;
  else
    return {
      status: activeFilter.status,
      period: {
        unit: activeFilter.intervalTimeUnit,
        interval: activeFilter.interval,
      },
    };
}

interface ActiveAssetsFilterProps {
  onChange(activeAssets?: ActiveAssets): void;

  defaultChecked?: ActiveAssets;
}

export default ActiveAssetsFilter;
