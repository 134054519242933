import { useState } from "react";
import { InvalidFeedBackProps } from "../../forms/components/SearchField";
import { macAddress } from "../../forms/validation";

export const useValidateMac = (): [
  InvalidFeedBackProps,
  (mac: string) => boolean
] => {
  const invalidCharactersMessage =
    "Diese Mac-Adresse enthält ungültige Zeichen!";

  const checkForNotValidChars = /[g-zG-Z]/;
  const [formFeedBack, setFormFeedBack] = useState<InvalidFeedBackProps>({
    invalid: false,
    message: "",
  });

  const validationPassed = (mac: string) => {
    const validation = macAddress(mac);

    if (checkForNotValidChars.test(mac)) {
      setFormFeedBack({ message: invalidCharactersMessage, invalid: true });
      return false;
    }

    if (validation) {
      setFormFeedBack({ message: validation, invalid: true });
      return false;
    }

    setFormFeedBack({ ...formFeedBack, invalid: false });
    return true;
  };

  return [formFeedBack, validationPassed];
};
