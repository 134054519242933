import moment from "moment";
import React, { memo } from "react";
import { Card, CardBody, Col, Media, Row } from "reactstrap";
import { IAlert } from "../../models/Alert";

type AlarmInfoProps = {
  alarm: IAlert;
  className?: string;
};

const AlarmInfo: React.FC<AlarmInfoProps> = ({ alarm, className }) => {
  const { firstName, lastName, gender, assignedLocation } = alarm.patient;

  return (
    <Card className={className}>
      <CardBody>
        <Row className="mb-3 align-items-center">
          <Col>
            <Media>
              <Media object src={`${process.env.PUBLIC_URL}/mitarbeiter.png`} />
            </Media>
          </Col>
          <Col>
            <h2>
              Alarm: <span className="text-danger">{alarm.description}</span>
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            TLM-Gerät:
            <br />
            Patient:
            <br />
            Geschlecht:
            <br />
          </Col>
          <Col>
            {alarm.id}
            <br />
            {firstName} {lastName}
            <br />
            {gender}
            <br />
          </Col>
          <Col>
            Stationiert:
            <br />
            Letztes Update:
            <br />
            {alarm.lastPosition?.technicalRoomNumber ? (
              <>
                Technische Raumnummer:
                <br />
              </>
            ) : null}
            Stockwerk:
          </Col>
          <Col>
            {assignedLocation}
            <br />
            {moment(alarm.lastPosition?.lastSeen).isValid() ? (
              moment(alarm.lastPosition?.lastSeen).format("DD.MM.YY HH:mm:ss")
            ) : (
              <span className="text-muted">Unbekannt</span>
            )}
            <br />
            {alarm.lastPosition?.technicalRoomNumber ? (
              <>
                {alarm.lastPosition.technicalRoomNumber}
                <br />
              </>
            ) : null}
            {alarm.lastPosition?.levelName ? (
              alarm.lastPosition?.levelName
            ) : (
              <span className="text-muted">Unbekannt</span>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default memo(AlarmInfo);
