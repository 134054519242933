import { ApolloQueryResult, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gql from "graphql-tag";
import React, { useCallback, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Button } from "reactstrap";
import { useGqlClient } from "../../hooks";
import { Tag } from "../../models/Tag";
import { Modal } from "../../shared";
import ActionButtons from "../../shared/ActionButtons";
import { DataTable, DataTableColum } from "../../shared/Tables/DataTable";

const DELETE_TAG = gql`
  mutation DeleteTag($id: ID!) {
    deleteTag(id: $id) {
      id
    }
  }
`;

function useDeleteTag() {
  const assetsClient = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_ASSETS!);
  return useMutation(DELETE_TAG, {
    client: assetsClient,
  });
}

function TagList({
  items: tags,
  onClickDelete,
  refetchAssetData,
}: TagListProps) {
  const { path } = useRouteMatch();
  const [modalTagId, setModalTagId] = useState<string>();
  const [deleteTag] = useDeleteTag();

  const handleDelete = useCallback(
    async (id: string) => {
      setModalTagId(undefined);
      try {
        await deleteTag({ variables: { id } });
        if (refetchAssetData !== undefined) {
          await refetchAssetData();
        }
      } catch (e) {
        console.error(e);
      } finally {
        if (onClickDelete !== undefined) {
          onClickDelete(id);
        }
      }
    },
    [deleteTag, onClickDelete, refetchAssetData]
  );

  const _generateActions = useCallback(
    (item: Tag) => {
      return [
        {
          text: "Bearbeiten",
          to: `${path}/edit/${item.id}`,
        },
        {
          text: "Löschen",
          color: "danger",
          disabled: item.isLocked,
          onClick: () => setModalTagId(item.id),
        },
      ];
    },
    [path]
  );

  const columns: DataTableColum<Tag>[] = [
    {
      key: "mac",
      label: "Mac-Adresse",
    },
    {
      key: "name",
      label: "Name",
    },
    {
      key: "actions",
      label: "Aktionen",
      valueAccessor: (item: Tag) => (
        <ActionButtons size="sm" actions={_generateActions(item)} />
      ),
    },
  ];

  return (
    <>
      <DataTable
        additionalHeader={
          <Button size="sm" color="success" tag={Link} to={`${path}/create`}>
            <FontAwesomeIcon icon="plus" />
          </Button>
        }
        columns={columns}
        rows={tags}
      />
      <Modal
        header="Löschen"
        body="Wollen Sie das Tag wirklich löschen?"
        show={modalTagId !== undefined}
        acceptButtonText="Löschen"
        acceptButtonColor="danger"
        onConfirm={() => modalTagId && handleDelete(modalTagId)}
        cancel={true}
        onCancel={() => setModalTagId(undefined)}
      />
    </>
  );
}

interface TagListProps {
  items: Tag[];
  onClickDelete?: (id: string) => void;
  refetchAssetData?: () => Promise<ApolloQueryResult<any>>;
}

export default TagList;
