import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "reactstrap";

enum ActionsControlNames {
  LOCK = "Sperren An/Aus",
  SAVE = "Änderungen speichern",
  REVERT = "Änderungen zurücksetzen",
  SIGNALRADIUS = "Ausleuchtung An/Aus",
  DEFAULTVIEW = "Standardansicht",
  SATELLITEVIEW = "Satellitenansicht",
}

const ActionsControl: React.FC<ActionsControlProps> = ({
  isSaveDisabled,
  onSave,
  isRevertDisabled,
  onRevert,
  isLocked,
  toggleLock,
  toggleSignal,
  isSatelliteViewDisabled,
  toggleSatelliteView,
  showSignalRadius,
}) => (
  <div className="button-section">
    <Button
      outline
      color={isLocked ? "primary" : "danger"}
      onClick={() => toggleLock()}
      title={ActionsControlNames.LOCK}
    >
      <FontAwesomeIcon icon={isLocked ? "lock" : "lock-open"} />
    </Button>
    <Button
      outline
      color="primary"
      disabled={isSaveDisabled}
      onClick={() => onSave()}
      title={ActionsControlNames.SAVE}
    >
      <FontAwesomeIcon icon="save" />
    </Button>
    <Button
      outline
      color="danger"
      disabled={isRevertDisabled}
      onClick={() => onRevert()}
      title={ActionsControlNames.REVERT}
    >
      <FontAwesomeIcon icon="history" />
    </Button>
    <Button
      outline
      color="primary"
      active={showSignalRadius}
      onClick={() => toggleSignal()}
      title={ActionsControlNames.SIGNALRADIUS}
    >
      <FontAwesomeIcon icon="signal" />
    </Button>
    {process.env.REACT_APP_MB_STYLE_SATELLITE!! !== "" && (
      <Button
        outline
        color="primary"
        onClick={() => toggleSatelliteView()}
        title={
          isSatelliteViewDisabled
            ? ActionsControlNames.DEFAULTVIEW
            : ActionsControlNames.SATELLITEVIEW
        }
      >
        <FontAwesomeIcon
          icon={isSatelliteViewDisabled ? "road" : "satellite"}
          fixedWidth
        />
      </Button>
    )}
  </div>
);

interface ActionsControlProps {
  isSaveDisabled: boolean;
  isRevertDisabled: boolean;
  isLocked: boolean;
  isSatelliteViewDisabled: boolean;
  showSignalRadius: boolean;
  toggleLock: () => void;
  onSave: () => void;
  onRevert: () => void;
  toggleSignal: () => void;
  toggleSatelliteView: () => void;
}

export default ActionsControl;
