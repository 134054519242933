import {
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Tooltip } from "reactstrap";
import { IAsset } from "../models/assets/Asset";
import "./Battery.scss";

const Battery: React.FC<IBatteryProps> = ({ asset }) => {
  const _lowBatteryLevel: number = 20;
  const _mediumBatteryLevel: number = 50;

  const [battery, setBattery] = useState<{
    color: string;
    icon: IconDefinition | null;
    tooltipText: string | undefined;
  }>({ color: "text-muted", icon: null, tooltipText: undefined });

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const _toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    const checkBatteryCapacity = () => {
      if (asset.tags.length === 0)
        setBattery({ color: "text-muted", icon: null, tooltipText: undefined });
      else {
        const { battery } = asset.tags[0];
        if (battery === undefined || battery === null)
          setBattery({
            color: "text-muted",
            icon: null,
            tooltipText: undefined,
          });
        else {
          if (battery < _lowBatteryLevel) {
            setBattery({
              color: "text-danger",
              icon: faBatteryQuarter,
              tooltipText: "Kapazität: Gering",
            });
          } else if (battery < _mediumBatteryLevel) {
            setBattery({
              color: "text-warning",
              icon: faBatteryHalf,
              tooltipText: "Kapazität: Mittel",
            });
          } else {
            setBattery({
              color: "text-success",
              icon: faBatteryFull,
              tooltipText: "Kapazität: Hoch",
            });
          }
        }
      }
    };
    checkBatteryCapacity();
  }, [asset]);

  const _tooltip = (
    <Tooltip
      className="BatteryTooltip"
      fade={false}
      placement="top"
      isOpen={tooltipOpen}
      target={`battery-tooltip-${asset.id}`}
      toggle={_toggle}
    >
      {battery.tooltipText}
    </Tooltip>
  );

  return (
    <>
      {battery.icon !== null ? (
        <div id={`battery-tooltip-${asset.id}`} className="d-inline-block">
          <FontAwesomeIcon icon={battery.icon} className={battery.color} />
          {_tooltip}
        </div>
      ) : (
        <span className="text-muted">Unbekannt</span>
      )}
    </>
  );
};

interface IBatteryProps {
  asset: IAsset;
}

export default Battery;
