import { useMutation } from "@apollo/client";
import { FORM_ERROR } from "final-form";
import gql from "graphql-tag";
import { useMemo } from "react";
import { Field, Form } from "react-final-form";
import { useRouteMatch } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Form as ReactstrapForm,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { InputField, TypeaheadField } from "../../forms";
import ErrorFeedback from "../../forms/components/ErrorFeedback";
import {
  composeValidators,
  macAddress,
  noScript,
  required,
} from "../../forms/validation";
import { useGqlClient } from "../../hooks";
import { BeaconType, Tag } from "../../models/Tag";

type FormModel = {
  name: string;
  mac: string;
  type: { label: string; value: BeaconType }[];
};

const CREATE_OR_UPDATE_TAG = gql`
  mutation CreateOrUpdateAsset($tagInput: TagInput!) {
    createOrUpdateTag(input: $tagInput) {
      id
      name
      ... on IBeacon {
        mac
      }
      ... on EddystoneBeacon {
        mac
      }
      ... on WLANClient {
        mac
      }
    }
  }
`;

function useCreateOrUpdateTag() {
  const assetsClient = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_ASSETS!);
  return useMutation(CREATE_OR_UPDATE_TAG, {
    client: assetsClient,
  });
}

function TagForm({ onSubmitted, tags }: TagFormProps) {
  const { params } = useRouteMatch<{ id: string }>();
  const [createOrUpdateTag] = useCreateOrUpdateTag();

  const tag = tags?.find((t) => t.id === params.id);

  const initialValues = useMemo(() => {
    if (tag === undefined) return undefined;

    let type;
    switch (tag.beaconType) {
      case BeaconType.WLAN:
        type = [{ label: "Wlan", value: BeaconType.WLAN }];
        break;
      case BeaconType.IBEACON:
        type = [{ label: "IBeacon", value: BeaconType.IBEACON }];
        break;
      case BeaconType.EDDYSTONE:
        type = [{ label: "Eddystone", value: BeaconType.EDDYSTONE }];
        break;
      default:
        type = undefined;
    }

    const formModel: Partial<FormModel> = {
      name: tag.name,
      mac: tag.mac,
      type: type,
    };
    return formModel;
  }, [tag]);

  const submitted = async (values: FormModel) => {
    const t: Partial<Tag> = {
      id: tag?.id,
      name: values.name,
      mac: values.mac,
      beaconType: values.type[0]?.value,
    };

    try {
      await createOrUpdateTag({
        variables: {
          tagInput: t,
        },
      });
      if (onSubmitted) {
        onSubmitted(t);
      }
    } catch (e) {
      console.error(e);
      const formErrors: any = {};

      if (e.graphQLErrors?.length > 0) {
        for (const error of e.graphQLErrors) {
          if (error.extensions.code === "UNKNOWN") {
            formErrors[FORM_ERROR] =
              "Beim Verarbeiten der Formulardaten ist ein unbekannter Fehler aufgetreten.";
          }

          if (error.extensions.code === "DUPLICATE_MAC") {
            formErrors.mac = "Diese Mac-Adresse ist bereits vergeben.";
          }
        }
      }
      return formErrors;
    }
  };

  return (
    <Form<FormModel> initialValues={initialValues} onSubmit={submitted}>
      {({
        handleSubmit,
        pristine,
        form,
        submitting,
        values,
        invalid,
        modifiedSinceLastSubmit,
        hasValidationErrors,
        hasSubmitErrors,
        submitError,
      }) => (
        <>
          <ReactstrapForm name="assetForm" onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="name">
                Name: <span className="text-danger">*</span>
              </Label>
              <Field
                name="name"
                type="text"
                maxLength="80"
                validate={composeValidators(required, noScript)}
                component={InputField}
              />
              <ErrorFeedback name="name" />
            </FormGroup>
            <FormGroup>
              <Label for="mac">
                Mac-Adresse: <span className="text-danger">*</span>
              </Label>
              <Field
                name="mac"
                type="text"
                maxLength="17"
                validate={composeValidators(required, macAddress)}
                component={InputField}
              />
              <ErrorFeedback name="mac" />
            </FormGroup>
            <FormGroup>
              <Label for="type">
                Typ: <span className="text-danger">*</span>
              </Label>
              <Field
                options={[
                  { label: "Wlan", value: BeaconType.WLAN },
                  { label: "IBeacon", value: BeaconType.IBEACON },
                  { label: "Eddystone", value: BeaconType.EDDYSTONE },
                ]}
                name="type"
                id="type"
                emptyLabel="Nichts gefunden.."
                labelKey="label"
                placeholder="Bitte wählen Sie..."
                validate={required}
                component={TypeaheadField}
              />
              <ErrorFeedback name="type" />
            </FormGroup>

            {submitError && (
              <Alert color="danger" className="mt-3">
                {submitError}
              </Alert>
            )}

            <Row className="mt-3">
              <Col xs="auto">
                <Button
                  block
                  color="danger"
                  onClick={() => form.reset()}
                  disabled={pristine || submitting}
                >
                  Änderungen zurücksetzen
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  block
                  color="success"
                  type="submit"
                  disabled={
                    (hasValidationErrors && hasSubmitErrors) ||
                    pristine ||
                    (invalid && !modifiedSinceLastSubmit)
                  }
                >
                  Speichern
                </Button>
              </Col>
            </Row>
          </ReactstrapForm>
        </>
      )}
    </Form>
  );
}

interface TagFormProps {
  onSubmitted?: (tag: Partial<Tag>) => void;
  tags?: Partial<Tag>[];
}

export default TagForm;
