import moment from "moment";
import React, { useState } from "react";
import { RangeModifier } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { Alert, Card, CardBody, CardHeader } from "reactstrap";
import { Loading } from "../../shared";
import useMeasurementsByRange from "../hooks/useMeasurementsByRange";
import "./AssetTelemetryDetail.scss";
import TelemetryDataChart from "./TelemetryDataChart";
import TemperatureFilters, { AggregationFunction, GroupBy } from "./TemperatureFilters";

interface AssetTelemetryDetailProps {
  tagMac: string | undefined;
  temperatureRange: {
    lower: number | null;
    upper: number | null;
  };
}

export default function AssetTelemetryDetail({ tagMac, temperatureRange }: AssetTelemetryDetailProps) {
  const [selectedDate, setSelectedDate] = useState<RangeModifier>({
    from: new Date(),
    to: new Date()
  });

  const [selectedFunction, setSelectedFunction] = useState<string>(
    AggregationFunction.MEAN
  );
  const [selectedGroupBy, setSelectedGroupBy] = useState<{
    id: string;
    value: string;
  }>(GroupBy.FIVEMINUTES);

  const {
    data: measurementData,
    loading: measurementLoading,
    error: measurementError,
  } = useMeasurementsByRange(
    tagMac,
    selectedDate.from!.toISOString(),
    selectedDate.to!.toISOString(),
    selectedFunction,
    selectedGroupBy.id
  );

  const filteredMeasurements = measurementData
    ? measurementData.getMeasurementsByRange
    .filter((item: any) => item.temperature !== null)
    .map((item: any) => {
      return {
        x: item.timestamp,
        y: item.temperature,
      };
    })
    : undefined;

  const modifiedMeasurementData: {
    id: any;
    color: string;
    data: {
      x: string;
      y: number;
    }[];
  } | undefined = filteredMeasurements ? {
    id: moment(selectedDate.from).format("YYYY-MM-DD"),
    color: "hsl(259, 70%, 50%)",
    data: filteredMeasurements,
  } : undefined;

  const lastMeasurementData: number | undefined =
    filteredMeasurements && filteredMeasurements.length
      ? filteredMeasurements[filteredMeasurements.length - 1].y
      : undefined;


  const onSelectDate = (date: RangeModifier) => {
    if (date.to && date.from) {
      setSelectedDate(date);
    }
  };

  const onSelectGroupBy = (group: { id: string; value: string }) => {
    setSelectedGroupBy(group);
  };

  const onSelectFunction = (selectFunction: string) => {
    setSelectedFunction(selectFunction);
  };

  return (
    <>
      {measurementError && (
        <Alert color="danger">Es ist ein unbekannter Fehler aufgetreten.</Alert>
      )}
      <Card id="assetTelemetryDetail">
        <CardHeader className="d-flex justify-content-between align-items-center">
          <span>
            Zuletzt gemessene Temperatur:{" "}
            {(lastMeasurementData && `${lastMeasurementData}°C`) ?? "-"}
          </span>
          <TemperatureFilters
            onSelectDate={onSelectDate}
            onSelectGroupBy={onSelectGroupBy}
            onSelectFunction={onSelectFunction}
            selectedGroupBy={selectedGroupBy}
            selectedFunction={selectedFunction}
            selectedDateRange={selectedDate}
            isLoading={measurementLoading}
          />
        </CardHeader>
        <CardBody style={{ height: 400, }}>
          {measurementLoading ? <Loading/> :
            <>
              {measurementError || !modifiedMeasurementData || modifiedMeasurementData?.data?.length === 0 ? (
                <Alert color="warning" className="d-flex justify-content-center">
                  Es sind keine Daten für den ausgewählten Tag vorhanden
                </Alert>
              ) : (
                <TelemetryDataChart
                  measurementData={modifiedMeasurementData}
                  range={temperatureRange}
                />)}
            </>
          }
        </CardBody>
      </Card>
    </>
  );
}
