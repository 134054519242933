import gql from "graphql-tag";

export const GET_MEASUREMENTS_BY_DAY = gql`
  query getMeasurementsByDay(
    $tagMac: ID!
    $day: String!
    $function: QueryFunction
    $groupBy: GroupBy
  ) {
    getMeasurementsByDay(
      tagMac: $tagMac
      day: $day
      function: $function
      groupBy: $groupBy
    ) {
      temperature
      timestamp
    }
  }
`;

export const GET_UPDATE_ON_MEASUREMENTS_BY_DAY = gql`
  subscription getUpdateOnMeasurementsByDay(
    $tagMac: ID!
    $day: String!
    $function: QueryFunction
    $groupBy: GroupBy
  ) {
    getUpdateOnMeasurementsByDay(
      tagMac: $tagMac
      day: $day
      function: $function
      groupBy: $groupBy
    ) {
      temperature
      timestamp
    }
  }
`;

export const GET_LAST_MEASUREMENT_BY_TAG_MAC = gql`
  query getLastMeasurementByTagMacs($tagMacs: [ID!]!) {
    getLastMeasurementByTagMacs(tagMacs: $tagMacs) {
      id
      temperature
    }
  }
`;

export const GET_UPDATE_ON_LAST_MEASUREMENT_BY_TAG_MAC = gql`
  subscription getUpdateOnLastMeasurementByTagMacs($tagMacs: [ID!]!) {
    getUpdateOnLastMeasurementByTagMacs(tagMacs: $tagMacs) {
      id
      temperature
    }
  }
`;

export const GET_MEASUREMENTS_BY_RANGE = gql`
  query getMeasurementsByRange(
    $tagMac: ID!
    $start: String!
    $end: String!
    $function: QueryFunction
    $groupBy: GroupBy
  ) {
    getMeasurementsByRange(
      tagMac: $tagMac
      start: $start
      end: $end
      function: $function
      groupBy: $groupBy
    ) {
      temperature
      timestamp
    }
  }
`;

export const GET_UPDATE_ON_MEASUREMENTS_BY_RANGE = gql`
  subscription getUpdateOnMeasurementsByRange(
    $tagMac: ID!
    $start: String!
    $end: String!
    $function: QueryFunction
    $groupBy: GroupBy
  ) {
    getUpdateOnMeasurementsByRange(
      tagMac: $tagMac
      start: $start
      end: $end
      function: $function
      groupBy: $groupBy
    ) {
      temperature
      timestamp
    }
  }
`;
