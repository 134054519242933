import React from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import { Alert, Card, CardBody, CardHeader, Col, Media, Row } from "reactstrap";
import { UseCase } from "../models/UseCase";
import { SecureRouteProps, SecureRouteWithSubRoutes } from "../routes";
import Loading from "../shared/Loading";
import useUseCases from "./hooks/useUseCases";
import "./UseCaseList.scss";

const UseCaseList: React.FC<UseCaseProps> = ({ routes }) => {
  const { data, error, loading } = useUseCases();
  const { path } = useRouteMatch();

  if (loading) {
    return <Loading />;
  }

  if (error !== undefined)
    return (
      <Alert color="danger">Es ist ein unbekannter Fehler aufgetreten.</Alert>
    );

  const _renderUseCase = (useCase: UseCase) => {
    return (
      <>
        <Card tag={Link} to={`${useCase.path}`} className="h-100">
          <CardHeader className="text-center">{useCase.name}</CardHeader>
          <CardBody className="align-self-center">
            <Media
              object
              className="useCaseImage"
              src={`${process.env.PUBLIC_URL}${useCase.icon}`}
            />
          </CardBody>
        </Card>
      </>
    );
  };
  return (
    <Switch>
      <Route exact path={path}>
        <h2>Module</h2>
        <Row className="align-items-stretch">
          {!data || data.useCases.length < 1 ? (
            <Col>
              <Alert color="danger" className="justify-content-center">
                Sie haben keine Berechtigungen für ein Modul. Bitte wenden Sie
                sich an Ihren Administrator.
              </Alert>
            </Col>
          ) : (
            data.useCases.map((item: UseCase, index: number) => (
              <Col className="mb-3" sm xl="auto" key={index}>
                {_renderUseCase(item)}
              </Col>
            ))
          )}
        </Row>
      </Route>
      {Object.entries(routes).map((value, index) => (
        <SecureRouteWithSubRoutes key={index} {...value[1]} />
      ))}
    </Switch>
  );
};
export default UseCaseList;

interface UseCaseProps {
  routes: Record<string, SecureRouteProps>;
}
