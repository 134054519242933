import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody, CardHeader, ListGroup, ListGroupItem, } from "reactstrap";
import "./styles/SelectableList.scss";

export function SelectableList<T extends { name: string; id: string }>({
  items,
  selectedItem,
  handleClick,
  header,
  icon,
  emptyListOverride,
}: SelectableListProps<T>) {
  return (
    <Card className="SelectableList shadow">
      {header && (
        <CardHeader>
          {header} {icon && <FontAwesomeIcon icon={icon}/>}
        </CardHeader>
      )}
      <CardBody className="p-0">
        <ListGroup flush>
          {items.map((item, index) => (
            <ListGroupItem
              onClick={() => {
                handleClick(item);
              }}
              key={index}
              className={item === selectedItem ? "fw-bold" : ""}
            >
              {item.name}
            </ListGroupItem>
          ))}
        </ListGroup>
        {items.length <= 0 && (
          <div className="text-muted text-center p-2">
            {emptyListOverride ?? "Keine Elemente vorhanden"}
          </div>
        )}
      </CardBody>
    </Card>
  );
}

export interface SelectableListProps<T> {
  items: T[];
  selectedItem: T | undefined;
  header?: string;
  emptyListOverride?: string;
  icon?: IconDefinition;
  handleClick: (item: T) => void;
}
