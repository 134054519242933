import React from "react";
import { Typeahead, TypeaheadProps } from "react-bootstrap-typeahead";
import { FieldInputProps } from "react-final-form";
import ErrorFeedback from "./ErrorFeedback";
import "./TypeaheadField.scss";

const TypeaheadField: React.FC<
  TypeaheadProps<string> & {
    input: FieldInputProps<string[]>;
    meta: {
      touched: boolean;
      error?: any;
      dirtySinceLastSubmit: boolean;
      submitError?: any;
    };
  }
> = ({ input, meta, options, ...rest }) => {
  const {
    value,
    onChange,
    onBlur,
  }: {
    value: string[];
    onChange: (selected: string[]) => void;
    onBlur: (selected: any) => void;
  } = input;

  const handleChange = (selected: string[]) => {
    onBlur(selected);
    onChange(selected);
  };

  return (
    <div className="TypeaheadField">
      <Typeahead
        isInvalid={
          !!(
            !meta.dirtySinceLastSubmit &&
            meta.touched &&
            (meta.error || meta.submitError)
          )
        }
        multiple={input.multiple}
        options={options}
        onChange={handleChange}
        selected={value ? value : []}
        {...rest}
      />
      <ErrorFeedback name={input.name} />
    </div>
  );
};

export default TypeaheadField;
