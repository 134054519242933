import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { IAsset } from "../../models/assets/Asset";
import ActionButtons, { Action } from "../../shared/ActionButtons";
import Battery from "../../shared/Battery";
import "../../shared/styles/ListView.scss";
import SortableTable, {
  SortableColumn,
} from "../../shared/Tables/SortableTable";
import { SortModel } from "../../shared/Tables/useSortableColumnsHook";
import { Order } from "../../models/Order";
import { PageCriteria } from "../../models/PageCriteria";

export const AssetListData: React.FC<IAssetListData> = ({
  name,
  data,
  setIPagination,
  iPagination,
  assetCount: assetsByUsecase,
  onGenerateActions,
}) => {
  const _sortModelChangeCallback = useCallback(
    (sortModel: SortModel) => {
      setIPagination({
        ...iPagination,
        sortBy: String(sortModel.sortBy),
        direction: sortModel.descending ? Order.DESC : Order.ASC,
      });
    },
    [iPagination, setIPagination]
  );

  const _renderHeader = () => (
    <div className="d-flex">
      <span>{name}</span>
      <span className="ms-auto">({assetsByUsecase})</span>
    </div>
  );

  const hasBattery = useMemo(
    () =>
      data.some((value) =>
        value.tags.some(
          (tag) => !(tag.battery === null || tag.battery === undefined)
        )
      ),
    [data]
  );

  const hasPosition = useMemo(
    () =>
      data.some(
        (value) =>
          !(value.lastPosition === null || value.lastPosition === undefined)
      ),
    [data]
  );
  const hasTemperature = useMemo(
    () =>
      data.some(
        (value) =>
          !(
            value.telemetry?.temperature === undefined ||
            value.telemetry.temperature === null
          )
      ),
    [data]
  );
  const hasFixedLocation = useMemo(
    () =>
      data.some(
        (value) =>
          !(
            value.technicalRoomNumber === undefined ||
            value.technicalRoomNumber === null
          )
      ),
    [data]
  );

  const hasTechnicalRoomNumber = useMemo(
    () =>
      data.some(
        (value) =>
          !(
            value.lastPosition?.technicalRoomNumber === undefined ||
            value.lastPosition.technicalRoomNumber === null
          )
      ),
    [data]
  );

  const hasInventoryNumber = useMemo(
    () =>
      data.some(
        (value) =>
          !(
            value.inventoryNumber === undefined ||
            value.inventoryNumber === null
          )
      ),
    [data]
  );

  const columns = useMemo(() => {
    const columnDefinitions: SortableColumn<IAsset>[] = [
      {
        key: "tags.name",
        label: "Beacon-ID",
        valueAccessor: (item) => item.tags[0]?.name,
        missingCellOverride: (
          <span className="text-muted">Nicht verknüpft</span>
        ),
      },
      { key: "name", label: "Objekt" },
    ];

    if (hasTemperature) {
      columnDefinitions.push({
        key: "telemetry.temperature",
        label: "Temperatur",
        valueFormatter: (value) => `${value} °C`,
        sortable: false,
      });
    }

    if (hasInventoryNumber) {
      columnDefinitions.push({
        key: "inventoryNumber",
        label: "Inventarnummer",
        sortable: true,
      });
    }

    if (hasPosition) {
      if (hasTechnicalRoomNumber) {
        columnDefinitions.push({
          key: "lastPosition.technicalRoomNumber",
          label: "Technische Raumnummer",
          sortable: false,
        });
      }
      columnDefinitions.push({
        key: "lastPosition.locatorName",
        label: "Letzter Standort",
        sortable: false,
      });
      columnDefinitions.push({
        key: "lastPosition.lastSeen",
        label: "Zuletzt Aktualisiert",
        sortable: false,
        valueFormatter: (value) => moment(value).format("DD.MM.YYYY HH:mm"),
      });
    } else {
      if (hasFixedLocation) {
        columnDefinitions.push({
          key: "technicalRoomNumber",
          label: "Fester Standort",
          sortable: false,
        });
      }
    }

    if (hasBattery) {
      columnDefinitions.push({
        key: "tags.battery",
        label: "Batterie",
        valueAccessor: (item) => <Battery asset={item} />,
      });
    }

    if (onGenerateActions !== undefined) {
      columnDefinitions.push({
        key: "actions",
        label: "Aktionen",
        sortable: false,
        valueAccessor: (item: IAsset) => (
          <ActionButtons size="sm" actions={onGenerateActions(item)} />
        ),
      });
    }

    return columnDefinitions;
  }, [
    hasBattery,
    hasTemperature,
    hasPosition,
    onGenerateActions,
    hasTechnicalRoomNumber,
    hasFixedLocation,
    hasInventoryNumber,
  ]);
  return (
    <SortableTable
      columns={columns}
      rows={data}
      additionalHeader={_renderHeader()}
      sortModel={{
        sortBy: iPagination.sortBy,
        descending: iPagination.direction === Order.DESC,
      }}
      onSortModelChange={_sortModelChangeCallback}
    />
  );
};

export interface IAssetListData {
  assetCount: number;
  data: IAsset[];
  iPagination: PageCriteria;
  name: string;
  onGenerateActions?: (item: IAsset) => Action[];
  setIPagination(pagination: PageCriteria): void;
}

export default AssetListData;
