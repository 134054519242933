import React, { useEffect } from "react";
import { Layer, Source, useMap } from "react-map-gl";
import { Feature, Point } from "geojson";
import { Position } from "../models/Position";
import { useSelectCurrentLayer } from "../asset/hooks/useSelectCurrentLayer";

function AssetLayer({
  asset,
  currentLayerId,
}: {
  asset: Feature<Point, Position>;
  currentLayerId?: string;
}) {
  const mapRef = useMap();

  useSelectCurrentLayer(currentLayerId);

  useEffect(() => {
    if (!mapRef.default?.hasImage("marker")) {
      mapRef.default?.loadImage(
        process.env.PUBLIC_URL + "/marker.png",
        (err: Error | any, image: Uint8Array | any) => {
          if (!err) {
            mapRef.default?.addImage("marker", image);
          }
        }
      );
    }
  }, [mapRef]);

  return (
    <Source id="assets" maxzoom={24} type="geojson" data={asset}>
      <Layer
        id={"assets"}
        type={"symbol"}
        filter={["all", ["has", "icon"]]}
        minzoom={13}
        layout={{
          "icon-image": [
            "coalesce",
            ["image", ["get", "icon"]],
            ["image", "marker"],
          ],
          "icon-anchor": "bottom",
          "icon-size": [
            "interpolate",
            ["exponential", 0.5],
            ["zoom"],
            14,
            0,
            25,
            1,
          ],
        }}
      />
    </Source>
  );
}

export default AssetLayer;
