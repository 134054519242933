import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";
import { Collapse } from "reactstrap";
import ArrowIcon from "../ArrowIcon";
import "../styles/DrilldownFilter.scss";
import { TreeContext } from "./TreeView";

const TreeItem: React.FC<TreeItemProps> = ({
  children,
  id,
  index,
  label,
  lastRootNode,
  onIconClick,
  onItemClick,
  selectedItem,
  size,
  depth,
}) => {
  const { selectNode, expandChildren } = useContext(TreeContext);
  const [expand, setExpand] = useState(false);
  const handleIconClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      setExpand(!expand);

      if (onIconClick && !expand) {
        onIconClick(id);
      }

      if (expandChildren && !expand) {
        expandChildren(id);
      }
    },
    [expandChildren, expand, id, onIconClick]
  );

  const handleItemClick = () => {
    if (onItemClick !== undefined) {
      onItemClick(id);
    }
    if (selectNode !== undefined) {
      selectNode(id);
    }
  };

  return (
    <li id={id}>
      <div
        className="tree-item d-flex align-items-center pe-2"
        style={{
          borderBottom:
            index !== undefined && size !== undefined
              ? !lastRootNode || expand || index < size - 1
                ? "1px solid rgba(0, 0, 0, 0.25)"
                : undefined
              : undefined,
          paddingLeft: `${depth * 2}rem`,
        }}
      >
        {children && <ArrowIcon onIconClick={handleIconClick} />}
        <span
          className={id === selectedItem ? "fw-bold" : ""}
          style={{
            paddingLeft: !children ? "2.3rem" : "",
          }}
          onClick={() => {
            handleItemClick();
          }}
        >
          {label}
        </span>
      </div>
      {children && (
        <Collapse isOpen={expand}>
          <ul className="list-unstyled ps-0 mb-0">{children}</ul>
        </Collapse>
      )}
    </li>
  );
};

interface TreeItemProps {
  id: string;
  index?: number;
  label: React.ReactNode;
  lastRootNode?: boolean;
  selectedItem: string;
  size?: number;
  onIconClick?: (id: string) => void;
  onItemClick?: (id: string) => void;
  depth: number;
}
export default React.memo<PropsWithChildren<TreeItemProps>>(TreeItem);
