import React from "react";
import { FieldRenderProps } from "react-final-form";
import { Input, InputProps } from "reactstrap";

type Props<T extends string | number | string[]> = FieldRenderProps<T> &
  InputProps;

function SelectField<T extends string | number | string[]>({
  input,
  meta,
  placeholder,
  options,
  ...rest
}: Props<T>) {
  return (
    <Input
      {...input}
      type="select"
      {...rest}
      invalid={!!(meta.touched && meta.error)}
    >
      {placeholder && !input.value && (
        <option value="" disabled>
          {placeholder}
        </option>
      )}
      {options.map((opt: { id: string; name: string }) => (
        <option key={opt.id} value={opt.id}>
          {opt.name}
        </option>
      ))}
    </Input>
  );
}

export default SelectField;
