import { library } from "@fortawesome/fontawesome-svg-core";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button } from "reactstrap";
import "./styles/ArrowIcon.scss";

library.add(faCaretDown);
library.add(faCaretRight);
const ArrowIcon: React.FC<IArrowIconProps> = ({ onIconClick }) => {
  const [toggle, setToggle] = useState(false);

  const changeToggle = () => {
    setToggle(!toggle);
  };

  return (
    <div className="arrow-icon">
      <Button
        color="link"
        onClick={(event) => {
          changeToggle();
          onIconClick(event);
        }}
      >
        <FontAwesomeIcon
          icon={toggle ? "caret-down" : "caret-right"}
          className={"fa-w-10"}
        />
      </Button>
    </div>
  );
};

interface IArrowIconProps {
  onIconClick(event: React.MouseEvent): void;
}

export default ArrowIcon;
