import moment from "moment";
import React from "react";
import { Card, CardBody, CardHeader, Col, Media, Row } from "reactstrap";
import { AssetService } from "..";
import { IAsset } from "../../models/assets/Asset";
import Battery from "../../shared/Battery";

export default function AssetMasterData({ asset }: AssetMasterDataProps) {
  const icon = AssetService.validateIcon(asset.icon);
  const lastSeenDate =
    asset.lastPosition?.lastSeen === undefined
      ? null
      : moment(asset.lastPosition?.lastSeen);

  const _renderTemperature = (asset: IAsset) => {
    if (!(asset && asset.allowedTemperatureRange)) return;
    else
      return (
        <>
          {asset.allowedTemperatureRange.upper !== null ? (
            <Row>
              <Col sm={4}>
                <span>Oberer Temperaturgrenzwert:</span>
              </Col>
              <Col sm={6}>
                <span>{asset.allowedTemperatureRange.upper.toFixed(1)}°C</span>
              </Col>
            </Row>
          ) : null}
          {asset.allowedTemperatureRange.lower !== null ? (
            <Row>
              <Col sm={4}>
                <span>Unterer Temperaturgrenzwert:</span>
              </Col>
              <Col sm={6}>
                <span>{asset.allowedTemperatureRange.lower.toFixed(1)}°C</span>
              </Col>
            </Row>
          ) : null}
        </>
      );
  };

  function renderField(value?: any, description?: string) {
    if (value === undefined || value === null || value === "") return null;

    return (
      <Row>
        <Col sm={4}>
          <span>{description}:</span>
        </Col>
        <Col sm={6}>
          <span>{value}</span>
        </Col>
      </Row>
    );
  }

  return (
    <Card className="mb-3" id="assetMasterData">
      <CardHeader>{asset.name}</CardHeader>
      <CardBody>
        <Row>
          <Col sm={{ size: "auto" }}>
            <Media>
              <Media
                className="border icon-border rounded-circle"
                object
                src={icon === "" ? "/placeholder.png" : icon}
              />
            </Media>
          </Col>
          <Col sm={6}>
            {asset.tags.some((value) => value.battery) ? (
              <Row>
                <Col sm={4}>
                  <span>Batterie:</span>
                </Col>
                <Col sm={6}>
                  <span>
                    <Battery asset={asset}/>
                  </span>
                </Col>
              </Row>
            ) : null}
            {_renderTemperature(asset)}
            {renderField(asset.description, "Beschreibung")}
            {renderField(asset.inventoryNumber, "Inventarnummer")}
          </Col>
          <Col>
            {asset.lastPosition?.levelName ? (
              <Row>
                <Col sm={6}>
                  <span>Stockwerk:</span>
                </Col>
                <Col sm={4}>{asset.lastPosition?.levelName}</Col>
              </Row>
            ) : null}
            {asset.lastPosition?.technicalRoomNumber ? (
              <Row>
                <Col sm={6}>
                  <span>Technische Raumnummer:</span>
                </Col>
                <Col>
                  {asset.technicalRoomNumber ??
                    asset.lastPosition?.technicalRoomNumber}
                </Col>
              </Row>
            ) : null}
            {asset.lastPosition?.locatorName ? (
              <Row>
                <Col sm={6}>
                  <span>Letzter Standort:</span>
                </Col>
                <Col>{asset.lastPosition.locatorName}</Col>
              </Row>
            ) : null}
            {asset.technicalRoomNumber ? (
              <Row>
                <Col sm={6}>
                  <span>Fester Standort:</span>
                </Col>
                <Col>{asset.technicalRoomNumber}</Col>
              </Row>
            ) : null}
            {lastSeenDate?.isValid() && !asset.telemetry?.temperature ? (
              <Row>
                <>
                  <Col sm={6}>
                    <span>Zuletzt gesehen:</span>
                  </Col>
                  <Col sm={4}>{lastSeenDate.format("DD.MM.YYYY HH:mm")}</Col>
                </>
              </Row>
            ) : null}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

interface AssetMasterDataProps {
  asset: IAsset;
  path?: string;
}
