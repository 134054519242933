import React from "react";
import { useField } from "react-final-form";
import { FormFeedback } from "reactstrap";

type Props = { name: string };

const ErrorFeedback = ({ name }: Props) => {
  const {
    meta: { touched, error, submitError, dirtySinceLastSubmit },
  } = useField(name, {
    subscription: {
      touched: true,
      error: true,
      submitError: true,
      dirtySinceLastSubmit: true,
    },
  });
  return !dirtySinceLastSubmit && touched && (error || submitError) ? (
    <FormFeedback>{error || submitError}</FormFeedback>
  ) : null;
};

export default ErrorFeedback;
