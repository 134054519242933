import React, { useCallback } from "react";
import {
  Pagination as PaginatationNavi,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import SortCategory from "../models/SortCategory";
import { PageCriteria } from "../models/PageCriteria";
import { PageSize } from "../models/PageSize";

const Pagination: React.FC<IPaginationProps> = ({
  pageCount,
  setIPagination,
  iPagination: { page, pageSize, sortBy, direction },
}) => {
  const _maxNavItem: number = 10;
  const _minNavItem: number = 1;
  const siteClick = useCallback(
    (changeTo: number) => {
      if (changeTo >= 0 && changeTo <= pageCount) {
        setIPagination({
          page: changeTo,
          pageSize: pageSize,
          sortBy: sortBy,
          direction: direction,
        });
      }
    },
    [direction, sortBy, pageCount, pageSize, setIPagination]
  );

  const numberElementClick = useCallback(
    (elementNumber: PageSize) => {
      setIPagination({
        page: 0,
        pageSize: elementNumber,
        sortBy: SortCategory.TAG_NAME,
        direction: direction,
      });
    },
    [direction, setIPagination]
  );

  const calculatePaginationListSize = () => {
    let length = _maxNavItem;
    if (_maxNavItem > pageCount) length = pageCount;

    let start = page - Math.floor(length / 2);
    start = Math.max(start, _minNavItem);
    start = Math.min(start, _minNavItem + pageCount - length);

    return Array.from({ length: length }, (el, i) => start + i);
  };

  const renderPagination = () => {
    return calculatePaginationListSize().map((element: number) => (
      <PaginationItem key={element} active={page + 1 === element}>
        <PaginationLink onClick={() => siteClick(element - 1)}>
          {element}
        </PaginationLink>
      </PaginationItem>
    ));
  };

  return (
    <div className="d-flex m-1">
      <PaginatationNavi className="m-auto">
        <PaginationItem disabled={page === 0}>
          <PaginationLink
            first
            onClick={() => {
              siteClick(0);
            }}
          />
        </PaginationItem>
        <PaginationItem disabled={page === 0}>
          <PaginationLink previous onClick={() => siteClick(page - 1)} />
        </PaginationItem>
        {renderPagination()}
        <PaginationItem disabled={page + 1 === pageCount}>
          <PaginationLink next onClick={() => siteClick(page + 1)} />
        </PaginationItem>
        <PaginationItem disabled={page + 1 === pageCount}>
          <PaginationLink
            last
            onClick={() => {
              siteClick(pageCount - 1);
            }}
          />
        </PaginationItem>
      </PaginatationNavi>

      <PaginatationNavi>
        <div className="align-self-center me-2">Pro Seite:</div>
        <PaginationItem
          active={pageSize === PageSize.TEN}
          onClick={() => numberElementClick(PageSize.TEN)}
        >
          <PaginationLink>10</PaginationLink>
        </PaginationItem>
        <PaginationItem
          active={pageSize === PageSize.TWENTY}
          onClick={() => numberElementClick(PageSize.TWENTY)}
        >
          <PaginationLink>20</PaginationLink>
        </PaginationItem>
        <PaginationItem
          active={pageSize === PageSize.FOURTY}
          onClick={() => numberElementClick(PageSize.FOURTY)}
        >
          <PaginationLink>40</PaginationLink>
        </PaginationItem>
      </PaginatationNavi>
    </div>
  );
};

interface IPaginationProps {
  pageCount: number;
  setIPagination(pagination: PageCriteria): void;
  iPagination: PageCriteria;
}

export default Pagination;
