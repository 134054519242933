import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalFooter } from "reactstrap";
import ReactMapGL, { LngLat, NavigationControl, ViewState } from "react-map-gl";
import "../styles/GeoCordsModal.scss";
import { Location, useLocations } from "../../location/hooks/useLocations";
import { levelFields } from "../../__generated__/types";
import { Loading } from "../../shared";
import { LevelSelectorDropdown } from "../../map/LevelSelectorDropdown";
import CuGeoJsonFloorplan from "../../catchupMaps/CuGeoJsonFloorplan";
import AssetLayer from "../../map/AssetLayer";

const GeoCordsModal = ({
  isOpen = false,
  initialValues,
  onToggle,
  onClosed,
}: GeoCordsModalProps) => {
  const { locationData, locationLoading } = useLocations(!isOpen);
  const [marker, setMarker] = useState<MarkerProps>();
  const [selectedLocation, setSelectedLocation] = useState<
    Location | undefined
  >(undefined);
  const [selectedLevel, setSelectedLevel] = useState<levelFields | undefined>(
    undefined
  );
  const [viewport, setViewport] = useState<ViewState>();

  const _onClick = (lngLat: LngLat) => {
    if (lngLat) {
      setMarker({
        latitude: lngLat.lat,
        longitude: lngLat.lng,
        levelId: selectedLevel?.id,
        locationId: selectedLocation?.id,
      });
    }
  };

  const _onToggle = (event: React.MouseEvent<any, MouseEvent>) => {
    setSelectedLevel(undefined);
    setSelectedLocation(undefined);
    onToggle(event);
  };

  const _onLevelSelected = (level: levelFields) => {
    if (level === selectedLevel) return;
    setSelectedLevel(level);
    if (marker) {
      setMarker({ ...marker, levelId: level.id });
    }
  };

  const _onLocationSelected = (event: ChangeEvent<HTMLInputElement>) => {
    const location = locationData?.find((l) => l.name === event.target.value);
    setSelectedLocation(location);
    setViewport(location?.viewport);
    if (initialValues?.locationId === location?.id) {
      const level = location?.availableLevels.find(
        (lvl) => lvl.id === initialValues?.levelId
      );
      setSelectedLevel(level);
    } else {
      setSelectedLevel(
        location?.availableLevels.find((level) => level.name === "0")
      );
    }
  };

  useEffect(() => {
    if (locationData && initialValues) {
      setMarker(initialValues);
      const location = locationData.find(
        (loc) => loc.id === initialValues.locationId
      );
      setSelectedLocation(location);
      if (location) {
        setViewport({
          ...location.viewport,
          latitude: initialValues.latitude,
          longitude: initialValues.longitude,
        });
      }

      const level = location?.availableLevels.find(
        (lvl) => lvl.id === initialValues.levelId
      );
      setSelectedLevel(level);
    } else if (locationData?.length === 1 && !initialValues) {
      setSelectedLocation(locationData[0]);
      setViewport(locationData[0].viewport);
      setSelectedLevel(locationData[0].availableLevels[0]);
    }
  }, [initialValues, locationData]);

  const geojson = {
    geometry: {
      coordinates: [marker?.longitude, marker?.latitude],
      type: "Point",
    },
    properties: {
      __typename: "Position",
      latitude: marker?.latitude,
      longitude: marker?.longitude,
      icon: "",
    },
    type: "Feature",
  };
  return (
    <Modal isOpen={isOpen} size="xl" toggle={_onToggle} scrollable>
      <div className="modal-header">
        <h5 className="modal-title d-inline-flex">Koordinaten verwalten</h5>
        <button
          onClick={onToggle}
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <ModalBody
        id="geocordsModalBody"
        className={selectedLocation ? "big" : "small"}
      >
        {locationLoading && <Loading />}
        {!locationLoading && (
          <>
            <Input
              id="location-select"
              type="select"
              onChange={_onLocationSelected}
              value={
                !selectedLocation ? "Standort wählen" : selectedLocation.name
              }
            >
              <option hidden>Standort wählen...</option>
              {locationData?.map((location, index) => (
                <option key={index}>{location.name}</option>
              ))}
            </Input>
            {selectedLocation && (
              <ReactMapGL
                {...viewport}
                mapboxAccessToken={process.env.REACT_APP_MB_ACCESS_TOKEN}
                mapStyle={process.env.REACT_APP_MB_STYLE}
                onMove={(evt) => setViewport(evt.viewState)}
                onClick={(e) => _onClick(e.lngLat)}
              >
                <CuGeoJsonFloorplan level={selectedLevel?.name ?? ""} use3D />
                <AssetLayer
                  asset={geojson as any}
                  currentLayerId={selectedLevel?.layerId}
                />
                <NavigationControl showCompass={false} />
                {locationData && selectedLevel && (
                  <LevelSelectorDropdown
                    availableLevels={selectedLocation.availableLevels}
                    currentLevel={selectedLevel}
                    onSelect={_onLevelSelected}
                  />
                )}
              </ReactMapGL>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button block color="danger" onClick={onToggle}>
          Abbrechen
        </Button>
        <Button
          block
          color="success"
          disabled={!marker}
          onClick={() => {
            onClosed(marker);
          }}
        >
          Speichern
        </Button>
      </ModalFooter>
    </Modal>
  );
};

interface GeoCordsModalProps {
  isOpen: boolean;
  initialValues?: MarkerProps;
  onClosed: (data: MarkerProps | undefined) => void;
  onToggle: React.MouseEventHandler<any>;
}

export interface MarkerProps {
  latitude: number;
  longitude: number;
  levelId?: string;
  locationId?: string;
}

export default GeoCordsModal;
