import React from "react";
import { Alert, Card, CardBody, Table } from "reactstrap";
import "./styles/ListView.scss";

class ListView extends React.Component<IListViewProps> {
  render() {
    return (
      <div className="ListView">
        <h2 className="d-flex justify-content-between">
          {this.props.name}
          {/* <button className="btn btn-success">
            <FontAwesomeIcon icon="plus" />
          </button> */}
        </h2>
        {this._renderTable()}
      </div>
    );
  }

  private _renderTable() {
    if (this.props.data.length <= 0) {
      return (
        <Alert color="warning">Es sind leider keine Einträge vorhanden.</Alert>
      );
    }

    return (
      <Card>
        <CardBody>
          <Table striped>
            <thead className="thead-light">
              <tr>{this.props.headingTemplate()}</tr>
            </thead>
            <tbody>
              {this.props.data.map((item: any, index: number) => (
                <tr key={index} className="ListViewItem">
                  {this.props.children(item)}
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}

interface IListViewProps {
  children(item: any): React.ReactNode;
  data: any[];
  itemTemplate: any;
  headingTemplate: any;
  name: string;
}

export default ListView;
