import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment, { Moment } from "moment";
import { ConditionalWrapper } from "./ConditionalWrapper";
import { createTheme } from "@material-ui/core/styles";

const MAX_DATE_MESSAGE = "Gewähltes Datum liegt in der Zukunft!";
const INVALID_DATE_MESSAGE = "Ungültiges Datumsformat!";
const CANCEL_LABEL = "Abbrechen";
const OK_LABEL = "Speichern";
const DATE_FORMAT = "DD.MM.YYYY HH:mm";

export function DatetimeRangePicker({
  startDate,
  endDate,
  error,
  onEndDateChange,
  onStartDateChange,
}: DatetimeRangePickerProps) {
  const minDate = moment().subtract(45, "days");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#08546b",
      },
    },
    overrides: {
      MuiInputBase: {
        input: {
          cursor: "pointer !important"
        }
      }
    }
  });

  return (
    <div className="btn bg-white shadow rounded-pill" style={{ cursor: "text" }}>
      <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
        <span className="d-inline-flex align-items-baseline">
          <div className="text-primary">Aufenthalt von:</div>
          <ConditionalWrapper
            condition={theme !== undefined}
            wrapper={(children) => (
              <ThemeProvider theme={theme!!}>{children}</ThemeProvider>
            )}
          >
            <DateTimePicker
              className="ms-1"
              ampm={false}
              cancelLabel={CANCEL_LABEL}
              format={DATE_FORMAT}
              invalidDateMessage={INVALID_DATE_MESSAGE}
              maxDateMessage={MAX_DATE_MESSAGE}
              okLabel={OK_LABEL}
              onChange={(date: Moment | null) => {
                onStartDateChange(date);
              }}
              minDate={minDate}
              value={startDate}
              disableFuture
              showTodayButton
              todayLabel="Jetzt"
            />
          </ConditionalWrapper>
        </span>
        <span className="d-inline-flex align-items-baseline ms-2">
          <div className="text-primary">bis:</div>
          <ConditionalWrapper
            condition={theme !== undefined}
            wrapper={(children) => (
              <ThemeProvider theme={theme!!}>{children}</ThemeProvider>
            )}
          >
            <DateTimePicker
              className="ms-1"
              ampm={false}
              cancelLabel={CANCEL_LABEL}
              format="DD.MM.YYYY HH:mm"
              invalidDateMessage={INVALID_DATE_MESSAGE}
              maxDateMessage={MAX_DATE_MESSAGE}
              okLabel={OK_LABEL}
              onChange={(date: any) => {
                onEndDateChange(date);
              }}
              minDate={minDate}
              value={endDate}
              disableFuture
              error={error}
              showTodayButton
              todayLabel="Jetzt"
            />
          </ConditionalWrapper>
        </span>
      </MuiPickersUtilsProvider>
    </div>
  );
}

export interface DatetimeRangePickerProps {
  startDate: Moment | null;
  endDate: Moment | null;
  error: boolean;
  onEndDateChange(endDate: Moment | null): void;
  onStartDateChange(startDate: Moment | null): void;
}
