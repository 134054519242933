import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import moment, { Moment } from "moment";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "reactstrap";
import { LevelSelector } from "../../map";
import { Loading } from "../../shared";
import { DatetimeRangePicker } from "../../shared/DatetimeRangePicker";
import { SelectableList } from "../../shared/SelectableList";
import useHistoricalPositionData from "../hooks/useHistoricalPositionData";
import Heatmap from "../maps/Heatmap";
import HeatmapLegend from "../maps/HeatmapLegend";

export default function AssetHistory() {
  const { id } = useParams<{ id: string }>();
  const [startDate, setStartDate] = useState<Moment | null>(
    moment().subtract(1, "week")
  );
  const [endDate, setEndDate] = useState<Moment | null>(moment());
  const dateError = useMemo(() => {
    return startDate !== null && endDate !== null && startDate > endDate;
  }, [startDate, endDate]);

  const {
    features,
    levels,
    currentLevel,
    noData,
    loading,
    error,
    selectLevel,
  } = useHistoricalPositionData(startDate, endDate, id, dateError);

  return (
    <>
      {loading && <Loading />}
      {error && !noData && (
        <Alert color="danger">Ein unbekannter Fehler ist aufgetreten</Alert>
      )}
      {noData && (
        <Alert color="warning">Es existieren keine Daten zu diesem Asset</Alert>
      )}
      {!error && !loading && (
        <div className="position-absolute top-0 start-0 w-100 h-100">
          <Heatmap
            key={currentLevel?.layerId}
            data={features}
            currentLevel={currentLevel}
          >
            {currentLevel && (
              <LevelSelector
                availableLevels={levels}
                currentLevel={currentLevel}
              />
            )}
          </Heatmap>
          <div className="position-absolute top-0 end-0 h-100 pe-none">
            <div className="d-flex flex-column align-items-end p-4 h-100">
              {dateError && (
                <Alert color="danger">
                  Ungültige <b>&nbsp;Datumsauswahl</b>. Das Enddatum darf nicht
                  <b>&nbsp;vor&nbsp;</b> dem Startdatum liegen.
                </Alert>
              )}
              <div className="d-flex pe-auto">
                <DatetimeRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  error={dateError}
                  onEndDateChange={setEndDate}
                  onStartDateChange={setStartDate}
                />
              </div>
              <div className="d-flex my-4 pe-auto">
                <SelectableList
                  items={levels}
                  selectedItem={currentLevel}
                  handleClick={selectLevel}
                  header="Ebenen"
                  icon={faLayerGroup}
                  emptyListOverride="Keine Ebenen vorhanden"
                />
              </div>
              <div className="d-flex mt-auto pe-auto">
                <HeatmapLegend />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
