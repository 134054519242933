import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Card, CardBody, CardHeader } from "reactstrap";
import { ROUTES } from "../../routes";
import { Loading } from "../../shared";
import AssetTemplate from "../components/AssetTemplate";
import { useAssetCreateOrUpdate } from "../hooks/useAssetCreateOrUpdate";
import { useAssetFormData } from "../hooks/useAssetFormData";

function AssetCreate() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const history = useHistory();

  const handleSuccess = useCallback(() => {
    history.push({
      pathname: ROUTES.home.path,
      state: {
        renderAlert: true,
        alertMessage: "Das Asset wurde erfolgreich erstellt",
      },
    });
  }, [history]);

  const {
    assetGroups,
    availableTags,
    availableUseCases,
    errors: formDataErrors,
    loading: formDataLoading,
    refetchAssetData,
  } = useAssetFormData();

  const {
    createOrUpdate,
    loading: mutationLoading,
    errors: mutationErrors,
  } = useAssetCreateOrUpdate(handleSuccess);

  useEffect(() => {
    setLoading(formDataLoading || mutationLoading);
  }, [formDataLoading, mutationLoading]);

  useEffect(() => {
    setError(formDataErrors || mutationErrors);
  }, [formDataErrors, mutationErrors]);

  return (
    <>
      {loading && <Loading />}
      <Alert color="danger" isOpen={error}>
        Es ist ein unbekannter Fehler aufgetreten.
      </Alert>
      <Card>
        <CardHeader>Asset erfassen</CardHeader>
        <CardBody>
          <AssetTemplate
            acceptButtonText="Erstellen"
            assetGroups={assetGroups}
            availableTags={availableTags}
            availableUseCases={availableUseCases}
            beaconLabel="Beacon (frei):"
            onAccept={createOrUpdate}
            refetchAssetData={refetchAssetData}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default AssetCreate;
