import React from "react";
import { Alert } from "reactstrap";
import { AssetService } from "../../asset";
import SingleAssetMap from "../../asset/maps/SingleAssetMap";
import AlarmInfo from "../components/AlarmInfo";
import { useAlarmBroadcastChannel } from "../hooks";

const AlarmDetail: React.FC = () => {
  const { data: alarm, error } = useAlarmBroadcastChannel();

  return (
    <>
      {error && (
        <Alert color="danger">Es ist ein unbekannter Fehler aufgetreten.</Alert>
      )}
      {!alarm && <Alert color="info">Bitte wählen Sie einen Alarm aus.</Alert>}
      {alarm && <AlarmInfo alarm={alarm} className="mb-3" />}

      {alarm && !alarm.lastPosition && (
        <Alert color="warning">Position konnte nicht bestimmt werden.</Alert>
      )}
      {alarm && alarm.lastPosition && !alarm?.lastPosition?.assetId && (
        <Alert color="danger">
          Es existiert kein Asset zu diesem TLM-Gerät.
        </Alert>
      )}
      {alarm && alarm.lastPosition && alarm.lastPosition.assetId && (
        <SingleAssetMap
          asset={AssetService.mapPositionToFeature(alarm.lastPosition)}
        />
      )}
    </>
  );
};

export default AlarmDetail;
