import React from "react";
import "./AlarmLegend.scss";

const AlarmLegend: React.FC<IAlarmLegendProps> = ({ assetGroups }) => {
  const uniqueAssetGroups = assetGroups.filter(
    (value, index, array) =>
      index ===
      array.findIndex((v) => v.assetGroupName === value.assetGroupName)
  );

  return (
    <div style={{ position: "absolute", bottom: "2rem", right: "1rem" }}>
      {uniqueAssetGroups.map((value, index: number) => (
        <div key={index} className="d-flex">
          <div
            className={"Legend-quader me-1 "}
            style={{
              backgroundColor: value.assetGroupColor,
            }}
          />
          {value.assetGroupName}
        </div>
      ))}
    </div>
  );
};

interface IAlarmLegendProps {
  assetGroups: { assetGroupName: string; assetGroupColor?: string }[];
}

export default AlarmLegend;
