import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useGqlClient } from "../../hooks";
import { BeaconType } from "../../models/Tag";
import useUseCases from "../../useCase/hooks/useUseCases";
import { GetAssetFormData } from "../queries/assetFormQueries";
import { useBuildingpartParents } from "./useBuildingpartParents";

function _createTag(t: {
  id: string;
  name: string;
  mac: string;
  __typename: string;
}) {
  let beaconType: BeaconType | undefined;

  switch (t.__typename) {
    case "IBeacon":
      beaconType = BeaconType.IBEACON;
      break;
    case "EddystoneBeacon":
      beaconType = BeaconType.EDDYSTONE;
      break;
    case "WLANClient":
      beaconType = BeaconType.WLAN;
      break;
  }
  return {
    id: t.id,
    name: t.name,
    mac: t.mac,
    beaconType: beaconType,
  };
}

export function useAssetFormData(assetId?: string) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<boolean>(false);
  const assetsClient = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_ASSETS!);

  const {
    data: assetData,
    loading: assetsLoading,
    error: assetsError,
    refetch: refetchAssetData,
  } = useQuery(GetAssetFormData, {
    client: assetsClient,
    variables: {
      id: assetId ?? "",
      withAsset: assetId !== undefined,
    },
  });
  const {
    data: useCaseData,
    loading: useCaseLoading,
    error: useCaseErrors,
  } = useUseCases(assetData?.useCases);

  const buildingpartParents = useBuildingpartParents(
    assetData?.asset?.buildingPartIDExternal
  );

  useEffect(() => {
    setLoading(assetsLoading || useCaseLoading);
  }, [assetsLoading, useCaseLoading]);

  useEffect(() => {
    setErrors(assetsError !== undefined || useCaseErrors !== undefined);
  }, [assetsError, useCaseErrors]);

  const tags = assetData?.availableTags.map(_createTag);
  let asset;
  if (assetData?.asset !== undefined) {
    asset = {
      ...assetData.asset,
      tags: assetData.asset.tags.map(_createTag),
    };
  }

  return {
    asset,
    assetGroups: assetData?.assetGroups ?? [],
    availableTags: tags ?? [],
    availableUseCases: useCaseData?.useCases ?? [],
    loading,
    errors,
    selectedBuildingparts: buildingpartParents,
    refetchAssetData,
  };
}
