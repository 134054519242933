import React, { useEffect, useState } from "react";
import {
  Button,
  Modal as ModalReactstrap,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const Modal: React.FC<IModalProps> = (props) => {
  const {
    header,
    body,
    acceptButtonText,
    acceptButtonColor,
    show,
    cancel,
    cancelButtonColor,
    onConfirm,
    onCancel,
  } = props;
  const [modal, setModal] = useState(show);

  useEffect(() => {
    setModal(show);
  }, [show]);

  return (
    <>
      <ModalReactstrap isOpen={modal}>
        <ModalHeader>{header}</ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <Button color={acceptButtonColor || "primary"} onClick={onConfirm}>
            {acceptButtonText}
          </Button>
          {cancel && (
            <Button color={cancelButtonColor || "secondary"} onClick={onCancel}>
              Abbrechen
            </Button>
          )}
        </ModalFooter>
      </ModalReactstrap>
    </>
  );
};

interface IModalProps {
  header: String;
  body: String;
  acceptButtonText: String;
  show: boolean;
  cancel?: boolean;
  onConfirm(): void;
  onCancel?(): void;
  acceptButtonColor?: string;
  cancelButtonColor?: string;
}

export default Modal;
