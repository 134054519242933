import { AssetService } from "..";
import {
  useHistoricalPositionsQuery,
  useLevelsByAssetIdAndPeriodQuery,
} from "../../__generated__/history";
import { useGqlClient } from "../../hooks";
import { Moment } from "moment";
import { Level } from "../../models/Level";
import { useCallback, useEffect, useMemo, useState } from "react";

export default function useHistoricalPositionData(
  startDate: Moment | null,
  endDate: Moment | null,
  assetId: string,
  dateError: boolean
) {
  const fallbackLevelIndex = 0;
  const client = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_HISTORY!);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [level, setLevel] = useState<Level | undefined>(undefined);
  const [noData, setNoData] = useState<boolean>(false);

  const {
    data: levelData,
    loading: levelLoading,
    error: levelError,
  } = useLevelsByAssetIdAndPeriodQuery({
    client,
    skip:
      !startDate?.isValid() ||
      !endDate?.isValid() ||
      startDate?.isSame(endDate) ||
      dateError,
    variables: {
      filter: {
        assetId: assetId,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      },
    },
  });

  const levels: Level[] = useMemo(() => {
    return (
      levelData?.levelsByAssetIdAndPeriod.map((mapLevel) => {
        return {
          id: mapLevel.id,
          name: mapLevel.name ?? "",
          layerId: mapLevel.layerId ?? "",
        };
      }) ?? []
    );
  }, [levelData]);

  const {
    data: positionData,
    loading: positionLoading,
    error: positionError,
  } = useHistoricalPositionsQuery({
    client,
    skip:
      !startDate?.isValid() ||
      !endDate?.isValid() ||
      level?.id === undefined ||
      dateError,
    variables: {
      filter: {
        assetId: assetId,
        levelId: level?.id,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      },
    },
  });

  const features = AssetService.mapPositionsToFeatureCollection(
    positionData?.historicalPositions.map((pos) => {
      return {
        latitude: pos.latitude ?? 0,
        longitude: pos.longitude ?? 0,
        duration: pos.duration ?? 0.0,
        radius: pos.radius ?? 0,
      };
    }) ?? []
  );

  useEffect(() => {
    setLevel(levels[fallbackLevelIndex]);
  }, [levels]);

  useEffect(() => {
    setLoading(levelLoading || positionLoading);
  }, [levelLoading, positionLoading]);

  useEffect(() => {
    setError(levelError !== undefined || positionError !== undefined);
  }, [levelError, positionError]);

  useEffect(() => {
    setNoData(levelError?.message.startsWith("There exist no data") ?? false);
  }, [levelError]);

  const selectLevel = useCallback((selected: Level) => {
    setLevel(selected);
  }, []);

  return {
    features,
    levels,
    currentLevel: level,
    noData,
    loading,
    error,
    selectLevel,
  };
}

export interface HistoricalPositionVariables {
  startDate: Moment | null;
  endDate: Moment | null;
  level: Level | null;
  assetId: string;
  dateError: boolean;
}
