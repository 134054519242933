import { combineReducers, configureStore } from "@reduxjs/toolkit";
import locatorMangerReducer from "./location/locatorManagerSlice";

const rootReducer = combineReducers({
  locatorManager: locatorMangerReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;

export default store;
