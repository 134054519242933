import {ApolloProvider} from "@apollo/client";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faTimesCircle, faUser as farUser,} from "@fortawesome/free-regular-svg-icons";
import {
  faArrowLeft,
  faArrowRight,
  faAssistiveListeningSystems,
  faBars,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faCog,
  faDownload,
  faEdit,
  faEllipsisV,
  faExclamationTriangle,
  faHistory,
  faInfo,
  faLaptop,
  faLayerGroup,
  faList,
  faLock,
  faLockOpen,
  faMapMarkedAlt,
  faMobileAlt,
  faPlus,
  faQuestionCircle,
  faRoad,
  faSatellite,
  faSatelliteDish,
  faSave,
  faSearch,
  faSignal,
  faSignOutAlt,
  faTachometerAlt,
  faUser as fasUser,
} from "@fortawesome/free-solid-svg-icons";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ApolloClientFactory from "./apolloClientFactory";
import { KeycloakProvider, useKeycloak } from "./auth/react-keycloak-wrapper";
import "./index.scss";
import Layout from "./layout/Layout";
import * as serviceWorker from "./serviceWorker";
import Loading from "./shared/Loading";
import store from "./store";
import { MapProvider } from "react-map-gl";

library.add(
  fasUser,
  farUser,
  faLock,
  faBars,
  faSearch,
  faExclamationTriangle,
  faInfo,
  faPlus,
  faMapMarkedAlt,
  faTachometerAlt,
  faMobileAlt,
  faSatelliteDish,
  faCog,
  faAssistiveListeningSystems,
  faChartLine,
  faSignOutAlt,
  faQuestionCircle,
  faTimesCircle,
  faArrowRight,
  faArrowLeft,
  faExclamationTriangle,
  faSave,
  faHistory,
  faLockOpen,
  faLayerGroup,
  faList,
  faEllipsisV,
  faLaptop,
  faDownload,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faCaretDown,
  faCaretUp,
  faEdit,
  faSignal,
  faCaretUp,
  faSatellite,
  faRoad
);

const queryClient = new QueryClient();

function Index() {
  const keycloak = useKeycloak();

  if (keycloak.loading) {
    return <Loading/>;
  }

  if (!keycloak.isAuthenticated) {
    keycloak.login({
      redirectUri: window.location.href,
    });
    return null;
  }

  const client = ApolloClientFactory.createClient({
    uri: process.env.REACT_APP_GRAPHQL_URI_LOCATION!,
    requestHandler: async (operation: any) => {
      const token = await keycloak.getTokenSilently();
      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : "",
        },
      });
    },
  });

  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <MapProvider>
              <Layout />
            </MapProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </ApolloProvider>
    </Provider>
  );
}

ReactDOM.render(
  <KeycloakProvider
    url={process.env.REACT_APP_KEYCLOAK_SERVER_URL}
    realm={process.env.REACT_APP_KEYCLOAK_REALM}
    clientId={process.env.REACT_APP_KEYCLOAK_RESOURCE}
  >
    <Index/>
  </KeycloakProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
