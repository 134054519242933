import { gql } from "@apollo/client";

export const eddystoneFragment = gql`
  fragment eddystoneFields on EddystoneBeacon {
    mac
    nId
    bId
    txPower
    advInterval
  }
`;

export const iBeaconFragment = gql`
  fragment ibeaconFields on IBeacon {
    mac
    uuid
    majorId
    minorId
    txPower
    advInterval
  }
`;

export const ASSET_BY_ID = gql`
  query Asset($id: ID!) {
    asset(id: $id) {
      id
      name
      icon
      description
      inventoryNumber
      allowedTemperatureRange {
        lower
        upper
      }
      tags {
        battery
        __typename
        ...ibeaconFields
        ...eddystoneFields
      }
      technicalRoomNumber
      lastPosition {
        latitude
        lastSeen
        layerId
        locatorId
        longitude
        levelId
        locationId
        locatorName
        levelName
        technicalRoomNumber
      }
    }
  }
  ${eddystoneFragment}
  ${iBeaconFragment}
`;

export const ALERT_ASSET_BY_ID = gql`
  query Asset($id: ID!) {
    asset(id: $id) {
      lastPosition {
        assetId
        assetName
        icon
        latitude
        lastSeen
        layerId
        locatorId
        longitude
        locatorName
        levelName
        technicalRoomNumber
      }
    }
  }
`;

export const GET_UPDATE_ON_POSITION_BY_ID = gql`
  subscription positionById($assetId: ID!) {
    positionById(assetId: $assetId) {
      assetId
      assetName
      icon
      latitude
      lastSeen
      layerId
      locatorId
      locationId
      levelId
      longitude
      locatorName
      levelName
      technicalRoomNumber
    }
  }
`;
