import { ResponsiveLine } from "@nivo/line";
import React from "react";
import { Card, CardBody } from "reactstrap";
import { CartesianMarkerProps } from "@nivo/core";

export default function TelemetryDataChart({ measurementData, range, }: TelemetryDataChartProps) {
  const temperatureRangeColor = "#FF6347";
  const defaultYScale = {
    max: 30.0,
    min: -10.0
  }

  const minValue = measurementData.data.reduce((previousValue, currentValue) => previousValue.y < currentValue.y ? previousValue : currentValue).y
  const maxValue = measurementData.data.reduce((previousValue, currentValue) => previousValue.y > currentValue.y ? previousValue : currentValue).y

  function getMarkers() {
    const markers: CartesianMarkerProps[] = [];
    if (range.lower !== null) {
      markers.push({
        axis: "y",
        value: range.lower,
        legend: `${range.lower}°C`,
        legendPosition: "right",
        lineStyle: { stroke: temperatureRangeColor },
        textStyle: { fill: temperatureRangeColor },
      });
    }
    if (range.upper !== null) {
      markers.push({
        axis: "y",
        value: range.upper,
        legend: `${range.upper}°C`,
        legendPosition: "right",
        lineStyle: { stroke: temperatureRangeColor },
        textStyle: { fill: temperatureRangeColor },
      });
    }
    return markers;
  }

  /**
   * Cannot use {@link CustomLayerProps} as type because of this:
   * https://github.com/plouc/nivo/issues/1947
   */
  function refsLayer({ yScale, innerHeight, innerWidth }: any) {
    const areaColor = "rgba(191, 191, 191, 1)";
    const inner = Math.abs(yScale(range.upper) - yScale(range.lower));
    const heightLowerArea = innerHeight - yScale(range.lower);
    const heightUpperArea = innerHeight - heightLowerArea - inner;
    if (!(range.upper || range.lower)) return;
    return (
      <g>
        {heightLowerArea > 0 ? (
          <rect
            y={heightLowerArea > innerHeight ? null : yScale(range.lower)}
            width={innerWidth}
            height={
              heightLowerArea > innerHeight ? innerHeight : heightLowerArea
            }
            opacity={0.2}
            fill={areaColor}
          />
        ) : null}
        {heightUpperArea > 0 ? (
          <rect
            width={innerWidth}
            height={
              heightUpperArea > innerHeight ? innerHeight : heightUpperArea
            }
            opacity={0.2}
            fill={areaColor}
          />
        ) : null}
      </g>
    );
  }

  return (
    <div style={{ position: "absolute", height: "80%", width: "92%" }}>
      {/*Above line is needed to make the responsive line shrink*/}
      <ResponsiveLine
        data={[measurementData]}
        markers={getMarkers()}
        margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
        xScale={{
          type: "time",
          format: "%Y-%m-%dT%H:%M:%S%Z",
          precision: "minute",
        }}
        xFormat="time:%Y-%m-%dT%H:%M:%S%Z"
        yScale={{
          type: "linear",
          min: minValue < defaultYScale.min ? minValue : defaultYScale.min,
          max: maxValue > defaultYScale.max ? maxValue : defaultYScale.max,
          stacked: false,
        }}
        axisBottom={{
          format: "%d.%m.%Y %H:%M",
          legend: "Datum & Uhrzeit",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        layers={[
          refsLayer,
          "grid",
          "markers",
          "axes",
          "areas",
          "crosshair",
          "lines",
          "points",
          "slices",
          "mesh",
          "legends",
        ]}
        tooltip={({ point }) => (
          <Card>
            <CardBody>
              <div>
                Datum: {(point.data.x as Date).toLocaleDateString()}
              </div>
              <div>
                Uhrzeit: {(point.data.x as Date).toLocaleTimeString()}
              </div>
              <div>Temperatur: {point.data.y}°C</div>
            </CardBody>
          </Card>
        )}
        axisLeft={{
          legend: "Temperatur",
          legendOffset: -50,
          legendPosition: "middle",
        }}
        colors={{ scheme: "nivo" }}
        useMesh={true}
        animate
      />
    </div>
  );
}

interface TelemetryDataChartProps {
  measurementData: {
    id: any;
    color: string;
    data: {
      x: string;
      y: number;
    }[];
  };
  range: {
    lower: number | null;
    upper: number | null;
  };
}
