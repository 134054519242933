import "moment/locale/de";
import React, { useEffect, useState } from "react";
import DayPicker, { DateUtils, RangeModifier } from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  UncontrolledDropdown,
} from "reactstrap";
import "./TemperatureFilters.scss";
import moment from "moment";

const TemperatureFilters: React.FC<ITemperatureFilters> = ({
  onSelectDate,
  onSelectFunction,
  onSelectGroupBy,
  selectedGroupBy,
  selectedFunction,
  selectedDateRange,
  isLoading,
}) => {
  const [groupByDropdownOpen, setGroupByDropdownOpen] = useState<boolean>(
    false
  );
  const [
    timeIntervalDropdownOpen,
    setTimeIntervalDropdownOpen,
  ] = useState<boolean>(false);
  const [
    dateRangePickerDropdownOpen,
    setDateRangePickerDropdownOpen,
  ] = useState<boolean>(false);

  let todayMinus45Days = new Date(); // because the retention of the influx bucket is 45 days
  todayMinus45Days.setDate(todayMinus45Days.getDate() - 45);

  useEffect(() => {
    if (isLoading) {
      setGroupByDropdownOpen(false);
      setTimeIntervalDropdownOpen(false);
      setDateRangePickerDropdownOpen(false);
    }
  }, [isLoading]);

  const locale = "de";
  const functionOptions = Object.values(AggregationFunction);
  const groupByOptions = Object.values(GroupBy);

  const initialDateRangeState = {
    from: undefined,
    to: undefined,
  };

  const [dateRangeState, setDateRangeState] = useState<RangeModifier>(
    initialDateRangeState
  );
  const { from, to }: RangeModifier = dateRangeState;
  const modifiers = { start: from as Date, end: to as Date };

  const handleDayClick = (day: Date) => {
    day.setHours(12);
    const range = DateUtils.addDayToRange(day, { from, to });
    setDateRangeState(range);

    let groupBy = adjustGroupByBySelectedDayRange(range);
    if (groupBy !== null) {
      onSelectGroupBy(groupBy);
    }
    onSelectDate(range);
  };

  /**
   * For better usability of the chart we adjust the GroupBy time so the data points are
   * accessible to the user.
   *
   * @param dateRange - selected date range
   */
  const adjustGroupByBySelectedDayRange = (dateRange: RangeModifier) => {
    if (!dateRange.from || !dateRange.to) return null;

    const start = moment(dateRange.from);
    const end = moment(dateRange.to);
    const dayDifference = end.diff(start, "days");

    let result;
    switch (true) {
      case dayDifference > 1 && dayDifference < 4:
        result = GroupBy.TENMINUTES;
        break;
      case dayDifference >= 4 && dayDifference < 8:
        result = GroupBy.THIRTYMINUTES;
        break;
      case dayDifference >= 8 && dayDifference < 14:
        result = GroupBy.ONEHOUR;
        break;
      case dayDifference >= 14 && dayDifference < 31:
        result = GroupBy.THREEHOURS;
        break;
      case dayDifference >= 31:
        result = GroupBy.SIXHOURS;
        break;
      default:
        result = GroupBy.FIVEMINUTES;
    }

    return result;
  };

  return (
    <span className="TemperatureFilters d-inline-flex">
      <UncontrolledDropdown
        className="me-2"
        isOpen={groupByDropdownOpen}
        toggle={() => {
          setGroupByDropdownOpen(!groupByDropdownOpen);
        }}
      >
        <DropdownToggle className="btn btn-sm btn-secondary" caret>
          Aggregiert nach: {selectedFunction}
        </DropdownToggle>
        <DropdownMenu right>
          {functionOptions.map((item: string, index: number) => (
            <DropdownItem
              key={index}
              toggle={false}
              onClick={(event) => {
                event.preventDefault();
                onSelectFunction(item);
              }}
            >
              <FormGroup check>
                <Label check for={"checkbox-" + index}>
                  <Input
                    id={"checkbox-" + index}
                    type="radio"
                    checked={item === selectedFunction}
                    value={item}
                    name="function"
                    readOnly
                  />
                  {item}
                </Label>
              </FormGroup>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown
        className="me-2"
        isOpen={timeIntervalDropdownOpen}
        toggle={() => {
          setTimeIntervalDropdownOpen(!timeIntervalDropdownOpen);
        }}
      >
        <DropdownToggle className="btn btn-sm btn-secondary" caret>
          Gruppiert nach: {selectedGroupBy.value}
        </DropdownToggle>
        <DropdownMenu right>
          {groupByOptions.map(
            (item: { id: string; value: string }, index: number) => (
              <DropdownItem
                key={index}
                toggle={false}
                onClick={(event) => {
                  event.preventDefault();
                  onSelectGroupBy(item);
                }}
              >
                <FormGroup check>
                  <Label check for={"checkbox-" + index}>
                    <Input
                      id={"checkbox-" + index}
                      type="radio"
                      checked={item.value === selectedGroupBy.value}
                      value={item.value}
                      name="groupBy"
                      readOnly
                    />
                    {item.value}
                  </Label>
                </FormGroup>
              </DropdownItem>
            )
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown
        isOpen={dateRangePickerDropdownOpen}
        toggle={() => {
          setDateRangePickerDropdownOpen(!dateRangePickerDropdownOpen);
        }}
      >
        <DropdownToggle className="btn btn-sm btn-secondary" caret>
          Ausgewähltes Datum:{" "}
          {selectedDateRange?.from?.toLocaleDateString(locale)} -{" "}
          {selectedDateRange?.to?.toLocaleDateString(locale)}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem toggle={false} className="withDayPicker">
            <DayPicker
              className="Selectable"
              localeUtils={MomentLocaleUtils}
              locale={locale}
              selectedDays={[from as Date, { from, to }]}
              modifiers={modifiers}
              numberOfMonths={1}
              onDayClick={handleDayClick}
              disabledDays={{ after: new Date(), before: todayMinus45Days }}
            />
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </span>
  );
};

interface ITemperatureFilters {
  onExport?(): void;
  onSelectFunction(operation: string): void;
  onSelectGroupBy(duration: { id: string; value: string }): void;
  onSelectDate(date: RangeModifier): void;
  selectedGroupBy: { id: string; value: string };
  selectedFunction: string;
  selectedDateRange: RangeModifier | undefined;
  downloadLink?: string;
  isLoading: boolean;
}

export const GroupBy = {
  FIVEMINUTES: { id: "FIVEMINUTES", value: "5 Minuten" },
  TENMINUTES: { id: "TENMINUTES", value: "10 Minuten" },
  THIRTYMINUTES: { id: "THIRTYMINUTES", value: "30 Minuten" },
  ONEHOUR: { id: "ONEHOUR", value: "1 Stunde" },
  THREEHOURS: { id: "THREEHOURS", value: "3 Stunden" },
  SIXHOURS: { id: "SIXHOURS", value: "6 Stunden" },
};

export const AggregationFunction = {
  MEAN: "MEAN",
  MEDIAN: "MEDIAN",
  FIRST: "FIRST",
  LAST: "LAST",
  MIN: "MIN",
  MAX: "MAX",
};

export default TemperatureFilters;
