import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useState } from "react";
import "./SearchField.scss";
import ReactInputMask from "react-input-mask";

const SearchField: React.FC<SearchFieldProps> = ({
  formFeedBack,
  inputPattern,
  onSubmit,
  placeHolder,
}) => {
  const [inputValue, setInputValue] = useState<string>("");

  const _onChange = (searchValue: string) => {
    setInputValue(searchValue);
  };

  return (
    <Form
      id="search-form"
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit(inputValue);
      }}
    >
      <FormGroup>
        <InputGroup>
          <ReactInputMask
            className={`me-1 form-control ${
              formFeedBack?.invalid ? "is-invalid" : ""
            }`}
            mask={inputPattern ?? ""}
            value={inputValue.toUpperCase()}
            placeholder={placeHolder}
            maskPlaceholder={null}
            onChange={(event) => _onChange(event.target.value)}
          />
          <InputGroupText className="p-0 bg-transparent border-0">
            <Button type="submit">
              <FontAwesomeIcon icon="search" />
            </Button>
          </InputGroupText>

          {formFeedBack && (
            <FormFeedback className={"invalid-feedback"}>
              {formFeedBack.message}
            </FormFeedback>
          )}
        </InputGroup>
      </FormGroup>
    </Form>
  );
};

export interface SearchFieldProps {
  onSubmit: (value: string) => void;
  placeHolder?: string;
  inputPattern?: string;
  formFeedBack?: InvalidFeedBackProps;
}
export default SearchField;

export interface InvalidFeedBackProps {
  invalid: boolean;
  message: string;
}
