import React from "react";
import { Card, CardBody, CardHeader, Form, FormGroup, Input, Label, ListGroup, ListGroupItem } from "reactstrap";
import { AssetGroup } from "../../models/assets/AssetGroup";

function AssetGroupFilter({
  assetGroups,
  defaultChecked,
  onClick,
}: AssetGroupFilterProps) {
  if (assetGroups.length <= 0) return null;

  const _handleClick = (groupId: string) => {
    if (defaultChecked.includes(groupId)) {
      onClick(defaultChecked.filter((checkedItem: string) => checkedItem !== groupId));
    } else {
      onClick([...defaultChecked, groupId]);
    }
  };

  return (
    <Card className="overflow-hidden">
      <CardHeader>Gruppen</CardHeader>
      <CardBody className="p-0">
        <ListGroup flush>
          {assetGroups.map((assetGroup: AssetGroup, index: number) => (
            <ListGroupItem key={index}>
              <Form>
                <FormGroup check>
                  <Input
                    type="checkbox"
                    id={assetGroup.id}
                    onClick={() => _handleClick(assetGroup.id)}
                    defaultChecked={defaultChecked.includes(assetGroup.id)}
                  />
                  <Label check htmlFor={assetGroup.id}> {assetGroup.name}</Label>
                </FormGroup>
              </Form>
            </ListGroupItem>
          ))}
        </ListGroup>
      </CardBody>
    </Card>
  );
}

interface AssetGroupFilterProps {
  assetGroups: AssetGroup[];
  defaultChecked: string[];

  onClick(groupIds: string[]): void;
}

export default AssetGroupFilter;
