import React from "react";
import { useHistory } from "react-router-dom";
import { Alert, Button } from "reactstrap";
import { ROUTES } from "../routes";

const ErrorDetail: React.FC<{ errorCode: number }> = ({ errorCode }) => {
  const history = useHistory();
  const codeToText: { [id: number]: string } = {
    403: "Sie verfügen nicht über die nötigen Berechtigungen um diese Seite aufzurufen.",
    404: "Die von Ihnen aufgerufene Seite existiert nicht.",
  };
  const code = errorCode === undefined ? 403 : errorCode;
  const displayText = codeToText[code];

  return (
    <>
      <Alert color="danger" className="d-block">
        <p>{displayText}</p>
        <div className="d-flex">
          <Button onClick={history.goBack}>Zurück zur vorherigen Seite</Button>
          <Button
            className="ms-1"
            onClick={() => history.push(ROUTES.home.childRoutes.useCases.path)}
          >
            Zurück zur Übersicht
          </Button>
        </div>
      </Alert>
    </>
  );
};

export default ErrorDetail;
