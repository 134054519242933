import { ApolloQueryResult, useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { useGqlClient } from "../../hooks";
import { LevelSelectorDropdown } from "../../map/LevelSelectorDropdown";
import { Loading, Sidebar } from "../../shared";
import { levelFields } from "../../__generated__/types";
import LocatorForm from "../components/LocatorForm";
import LocatorManager, { Locator } from "../components/LocatorManager";
import { useLocationById } from "../hooks/useLocationById";
import {
  selectActiveLocator,
  selectCurrentLevel,
  selectHasChanges,
  setCurrentLevel,
} from "../locatorManagerSlice";
import "../styles/LocatorEdit.scss";

const LOCATORS_BY_LEVELID = gql`
  query GetLocatorsByLevelId($levelId: ID!) {
    getLocatorsByLevelId(levelId: $levelId) {
      id
      customerId
      level {
        id
        layerId
      }
      mac
      name
      type
      radius
      rssiCalibration
      buildingPartExternalId
      viewport {
        latitude
        longitude
      }
    }
  }
`;

const UPDATE_LOCATORS = gql`
  mutation UpdateLocators(
    $createdLocators: [LocatorInput!]!
    $updatedLocators: [LocatorInput!]!
    $deletedLocatorIds: [ID!]!
  ) {
    createLocators(locatorInput: $createdLocators)
    updateLocators(locatorInput: $updatedLocators)
    deleteLocators(locatorIds: $deletedLocatorIds)
  }
`;

export const LocatorRefetchContext = createContext<
  { refetch?: () => Promise<ApolloQueryResult<void>> } | undefined
>(undefined);

const LocatorEdit: React.FC = () => {
  const [selectedLevel, setSelectedLevel] = useState<levelFields>();
  const [isSucessOpen, setSuccessOpen] = useState<boolean>(false);
  const [isErrorOpen, setErrorOpen] = useState<boolean>(false);
  const [modal, setModal] = useState(false);
  const match = useRouteMatch<{ id: string }>();
  const activeLocator = useSelector(selectActiveLocator).locator;
  const currentLevel = useSelector(selectCurrentLevel);
  const hasChanges = useSelector(selectHasChanges);
  const dispatch = useDispatch();
  const locationClient = useGqlClient(
    process.env.REACT_APP_GRAPHQL_URI_LOCATION!
  );

  const [updateLocators] = useMutation(UPDATE_LOCATORS, {
    client: locationClient,
  });

  const [loadLocators, locatorResult] = useLazyQuery(LOCATORS_BY_LEVELID, {
    client: locationClient,
    variables: { levelId: currentLevel?.id },
  });

  const locators = useMemo(
    () => _mapToLocators(locatorResult.data?.getLocatorsByLevelId),
    [locatorResult.data]
  );

  const { locationData, locationError, locationLoading } = useLocationById(
    match.params.id
  );

  useEffect(() => {
    if (locationData) {
      const currentLevel = locationData.location?.availableLevels.find(
        (levels) => levels.name === "0"
      );
      if (currentLevel !== undefined) {
        dispatch(setCurrentLevel(currentLevel));
      } else {
        if (locationData.location === null) return;
        dispatch(setCurrentLevel(locationData.location?.availableLevels[0]));
      }
    }
  }, [dispatch, locationData]);

  if (currentLevel && !locatorResult.called) {
    loadLocators();
  }

  const _toggleModal = () => setModal(!modal);

  const _onSave = async (
    createdLocators: Locator[],
    updatedLocators: Locator[],
    deletedLocatorIds: string[]
  ) => {
    try {
      await updateLocators({
        variables: {
          createdLocators,
          updatedLocators,
          deletedLocatorIds,
        },
      });

      setSuccessOpen(true);
      setTimeout(() => {
        setSuccessOpen(false);
      }, 3000);
      locatorResult.refetch!();
    } catch {
      setErrorOpen(true);
      setTimeout(() => {
        setErrorOpen(false);
      }, 3000);
    }
  };

  const _onLevelSelected = (level: levelFields) => {
    if (level === currentLevel) return;

    setSelectedLevel(level);
    if (hasChanges) {
      _toggleModal();
    } else {
      _changeLevel(level);
    }
  };

  const refetchLocators = useCallback(() => {
    return locatorResult.refetch();
  }, [locatorResult]);

  const _changeLevel = (level?: levelFields) => {
    if (!level) return;

    if (hasChanges) {
      _toggleModal();
    }

    dispatch(setCurrentLevel(level));
  };

  if (locationError)
    return (
      <Alert color="danger">Es ist ein Fehler beim Laden aufgetreten.</Alert>
    );

  if (
    !(locationData?.location && locationData.location.viewport) &&
    !locationLoading
  )
    return <Alert color="warning">Standort leider nicht gefunden.</Alert>;

  if (!currentLevel && !locationLoading)
    return <Alert color="warning">Keine Ebenen verfügbar.</Alert>;

  return (
    <>
      {locationLoading && <Loading />}
      <Row noGutters className="align-items-stretch LocatorEdit">
        <Col xs="10">
          {locationData?.location && currentLevel !== null && (
            <LocatorRefetchContext.Provider
              value={{ refetch: refetchLocators }}
            >
              <LocatorManager
                initialLocators={locators}
                initialViewport={locationData.location.viewport}
                onSave={_onSave}
              >
                <LevelSelectorDropdown
                  availableLevels={locationData.location.availableLevels}
                  currentLevel={currentLevel}
                  onSelect={_onLevelSelected}
                />
              </LocatorManager>
            </LocatorRefetchContext.Provider>
          )}
          <Alert isOpen={isSucessOpen} color="success" role="alert">
            Änderungen wurden erfolgreich gespeichert.
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setSuccessOpen(false)}
            />
          </Alert>
          <Alert isOpen={isErrorOpen} color="danger" role="alert">
            Änderungen konnten leider nicht gespeichert werden.
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setErrorOpen(false)}
            />
          </Alert>
        </Col>
        <Col xs="2">
          <Sidebar>
            <LocatorForm
              initialValues={activeLocator ? activeLocator : undefined}
            />
          </Sidebar>
        </Col>
      </Row>

      <Modal isOpen={modal}>
        <div className="modal-header">
          <h5 className="modal-title">Sind Sie wirklich sicher?</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => _toggleModal()}
          />
        </div>
        <ModalBody>
          Durch einen Wechsel des Stockwerks gehen die bereits getätigten
          Änderungen verloren.
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            outline
            onClick={() => _changeLevel(selectedLevel)}
          >
            Stockwerk wechseln
          </Button>{" "}
          <Button color="secondary" outline onClick={_toggleModal}>
            Abbrechen
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const _mapToLocators = (data: any[]) => {
  if (!data) return [];
  return data.map((item) => {
    const locator: Locator = {
      id: item.id,
      customerId: item.customerId,
      levelId: item.level.id,
      layerId: item.level.layerId,
      mac: item.mac,
      name: item.name,
      type: item.type,
      longitude: item.viewport.longitude,
      latitude: item.viewport.latitude,
      radius: item.radius,
      rssiCalibration: item.rssiCalibration,
      buildingPartIDExternal: item.buildingPartExternalId,
    };
    return locator;
  });
};

export default LocatorEdit;
