import { useCallback, useContext } from "react";
import { levelFields } from "../../__generated__/types";
import { findAndToggleLocator, setCurrentLevel } from "../locatorManagerSlice";
import { ViewState } from "react-map-gl";
import {
  InvalidFeedBackProps,
  SearchFieldProps,
} from "../../forms/components/SearchField";
import { useDispatch } from "react-redux";
import { Locator } from "../components/LocatorManager";
import { useValidateMac } from "./useValidateMac";
import { useLocatorByMac } from "./useLocatorByMac";
import { FlyToInterpolator } from "@deck.gl/core";
import { LocatorRefetchContext } from "../pages/LocatorEdit";

export const useMacSearchField = (
  setViewport: (viewPort: ViewState) => void
): SearchFieldProps => {
  const TRANSITION_SPEED_IN_MILLI = 2000;
  const TRANSITION_DURATION_IN_MILLI = 2500;
  const MAC_ADDRESS_PATTERN = "**:**:**:**:**:**";
  const PLACEHOLDER = "Nach Mac-Adresse suchen...";
  const LOCATOR_NOT_FOUND_FEEDBACK = {
    invalid: true,
    message: "Keine Empfänger zu der angegebenen Mac-Adresse gefunden.",
  };
  const [formFeedBack, validate] = useValidateMac();
  const [fetchLocatorByMac, locatorData] = useLocatorByMac();
  const locatorsContext = useContext(LocatorRefetchContext);
  const dispatch = useDispatch();

  const setLocator = useCallback(
    (locator) => {
      if (locator) {
        setViewport({
          ...locator.viewport,
          transitionInterpolator: new FlyToInterpolator({
            speed: TRANSITION_SPEED_IN_MILLI,
          }),
          transitionDuration: TRANSITION_DURATION_IN_MILLI,
        });

        dispatch(findAndToggleLocator(locator));
      }
    },
    [dispatch, setViewport]
  );

  const onSubmit = useCallback(
    async (mac: string) => {
      const validation = validate(mac);
      if (validation) {
        const { data } = await fetchLocatorByMac({
          variables: { macAddress: mac },
        });
        if (data.locatorByMac) {
          const levelField: levelFields = data.locatorByMac.level;
          dispatch(setCurrentLevel(levelField));
          const locator: Locator = {
            ...data.locatorByMac,
            latitude: data.locatorByMac.viewport.latitude,
            longitude: data.locatorByMac.viewport.longitude,
          };
          if (locatorsContext?.refetch) {
            await locatorsContext?.refetch();
            setLocator(locator);
          }
        }
      }
    },
    [locatorsContext, dispatch, fetchLocatorByMac, setLocator, validate]
  );

  const getFormFeedBack = (feedBack: InvalidFeedBackProps) => {
    if (feedBack.invalid) {
      return feedBack;
    }

    if (locatorData === null) {
      return LOCATOR_NOT_FOUND_FEEDBACK;
    }

    return feedBack;
  };
  return {
    onSubmit,
    inputPattern: MAC_ADDRESS_PATTERN,
    formFeedBack: getFormFeedBack(formFeedBack),
    placeHolder: PLACEHOLDER,
  };
};
