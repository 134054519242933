import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useField } from "react-final-form";
import { ActionMeta, OptionsType } from "react-select";
import useDrilldownData, { Node, TreeNode } from "../../hooks/drilldownData";
import FixedMultiSelect, { FixedMultiSelectProps } from "./FixedMultiSelect";

function useOptionsFromBuildingparts(buildingparts: TreeNode[]) {
  const [options, setOptions] = useState<OptionsType<Option>>();

  const resetOptions = useCallback((bp: TreeNode[]) => {
    const generateOptions = (nodes: TreeNode[]) =>
      nodes.map((part) => {
        return { value: part.id, label: part.name };
      });

    setOptions(generateOptions(bp));
  }, []);

  useEffect(() => {
    if (options === undefined && buildingparts.length > 0) {
      resetOptions(buildingparts);
    }

    if (!_.isEmpty(options) && _.isEmpty(buildingparts)) {
      resetOptions(buildingparts);
    }
  }, [options, buildingparts, resetOptions]);

  const findAndSetOptions = useCallback(
    (id?: string) => {
      if (!id) return;
      buildingparts.forEach((item) => {
        const children = item.findNode(id)?.childNodes;
        if (children !== undefined) {
          resetOptions(children);
        }
      });
    },
    [buildingparts, resetOptions]
  );

  return { options: options ?? [], findAndSetOptions, resetOptions };
}

export function BuildingpartSelect({
  onSelect,
  selectedBuildingparts,
  value,
  name,
  ...rest
}: BuildingpartSelectProps) {
  const field = useField<OptionsType<{ label: string; value: string }>>(name);
  const currentBuildingpart = useMemo(() => {
    if (Array.isArray(field.input.value) && field.input.value.length > 0) {
      return _.last(field.input.value)?.value;
    }
  }, [field.input]);

  const { buildingTree: buildingparts, loading } = useDrilldownData(
    currentBuildingpart,
    selectedBuildingparts,
    false
  );

  const {
    options,
    findAndSetOptions,
    resetOptions,
  } = useOptionsFromBuildingparts(buildingparts);

  useEffect(() => {
    findAndSetOptions(currentBuildingpart);
  }, [findAndSetOptions, currentBuildingpart]);

  useEffect(() => {
    if (_.isEmpty(value)) {
      resetOptions(buildingparts);
    }
  }, [value, resetOptions, buildingparts]);

  const handleChange = (
    value: OptionsType<Option>,
    actionMeta: ActionMeta<Option>
  ) => {
    switch (actionMeta.action) {
      case "select-option":
        const id = actionMeta.option?.value;
        findAndSetOptions(id);
        if (onSelect) {
          onSelect(value);
        }
        break;
      case "remove-value":
      case "pop-value":
        if (value.length >= 1) {
          const parentId = _.last(value)?.value;
          findAndSetOptions(parentId);
        } else {
          resetOptions(buildingparts);
        }

        if (onSelect) {
          onSelect(value);
        }
        break;
      case "clear":
        resetOptions(buildingparts);
        if (onSelect) {
          onSelect(null);
        }
        break;
    }
  };

  return (
    <FixedMultiSelect
      loadingMessage={() => "Lade weitere Optionen..."}
      noOptionsMessage={() => "Keine weiteren Optionen"}
      options={options}
      onChange={(value, actionMeta) => handleChange(value, actionMeta)}
      value={value}
      isLoading={loading}
      {...rest}
    />
  );
}

type Option = {
  label: string;
  value: string;
};

export type BuildingpartSelectProps = FixedMultiSelectProps<{
  label: string;
  value: string;
}> & {
  onSelect?: (selection: OptionsType<Option> | null) => void;
  selectedBuildingparts?: Node[];
  name: string;
};

export default BuildingpartSelect;
