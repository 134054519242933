import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Location } from "../../models/Location";
import { rolePermission } from "../../rolePermission";
import ActionButtons from "../../shared/ActionButtons";
import { DataTable } from "../../shared/Tables/DataTable";

export const LocationListTable: React.FC<LocationListTableProps> = ({
  locations,
}) => {
  const match = useRouteMatch();

  return (
    <DataTable
      columns={[
        { key: "name", label: "Name" },
        { key: "locatorCount", label: "Anzahl Empfänger" },
        {
          key: "actions",
          label: "Aktionen",
          valueAccessor: (location) => (
            <ActionButtons
              size="sm"
              actions={[
                {
                  text: "Bearbeiten",
                  to: `${match.url}/${location.id}/edit`,
                  role: rolePermission.LOCATION_EDIT,
                },
                {
                  text: "Excel Export",
                  to:
                    process.env.REACT_APP_REST_URI_DOWNLOAD_LOCATOR! +
                    location.id,
                  handleAsExternal: true,
                  role: rolePermission.LOCATION_READ,
                },
              ]}
            />
          ),
        },
      ]}
      rows={locations}
    />
  );
};

export interface LocationListTableProps {
  locations: Location[];
}

export default LocationListTable;
