import {useQuery} from "@apollo/client";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {generatePath, useHistory, useLocation} from "react-router-dom";
import Select from "react-select";
import {Alert, Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import {AssetService} from "../../asset";
import useAssetDelete from "../../asset/hooks/useAssetDelete";
import {useKeycloak} from "../../auth";
import useDrilldownData from "../../hooks/drilldownData";
import useAssets from "../../hooks/useAssets";
import useGqlClient from "../../hooks/useGqlClient";
import {ROUTES} from "../../routes";
import {Modal as HyprosModal} from "../../shared";
import Loading from "../../shared/Loading";
import AssetTrackingTemplate from "../components/AssetTrackingTemplate";
import useLastMeasurementByTagMacs from "../hooks/useLastMeasurementByTagMacs";
import {GET_USE_CASE} from "../queries/useCaseQueries";
import "./TemperatureMonitoring.scss";

const NUM_MONTHS_EXPORT = parseInt(
  process.env.REACT_APP_TEMPERATURE_EXPORT_MONTHS!
);

const TemperatureMonitoring: React.FC<{
  name: string;
  editRole: string;
  deleteRole: string;
  readRole: string;
}> = ({name, editRole, deleteRole, readRole}) => {
  const alertTime: number = 4000;

  const assetsClient = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_ASSETS!);

  // prepare date export options; only the current and the last to months are allowed!
  const dateExportOptions = () => {
    const options = [];
    const formatter = new Intl.DateTimeFormat("de", {
      year: "numeric",
      month: "long",
    });

    for (let i = NUM_MONTHS_EXPORT; i >= 0; i--) {
      const month = new Date();
      if (i > 0) {
        month.setDate(1);
        month.setMonth(month.getMonth() - i);
      }
      options.push({
        value: month,
        label: formatter.format(month),
      });
    }

    return options;
  };

  const {state} = useLocation<any>();
  const history = useHistory();

  const [dateExportMonth, setDateExportMonth] = useState<{
    value: Date;
    label: string;
  }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<boolean>(false);
  const [assetDeleteId, setAssetDeleteId] = useState<string>("");
  const [showModal, setModalShow] = useState<boolean>(false);
  const [alertShow, setAlertShow] = useState<boolean>(false);
  const [showDownloadForm, setShowDownloadForm] = useState<boolean>(false);
  const [rootID, setRootID] = useState<string | undefined>(undefined);
  const [alertInput, setAlertInput] = useState<{
    show: boolean;
    color: string;
    message: string;
  }>({
    show: false,
    color: "",
    message: "",
  });
  const keycloak = useKeycloak();

  const {
    data: useCaseData,
    loading: useCaseLoading,
    error: useCaseError,
  } = useQuery(GET_USE_CASE, {
    client: assetsClient,
    variables: {
      name: name,
    },
  });

  const useCaseId = useCaseData?.useCase?.id;

  const {
    assetPage,
    loading: assetLoading,
    error: assetError,
    filterData: {filterCriteria, pageCriteria},
    refetch,
    setGroupFilter,
    setBuildingPart,
    setPagination,
  } = useAssets(useCaseId, false);

  const tagMacs = useMemo(() => {
    return AssetService.getDistinctTagMacsFromAssets(assetPage.assets);
  }, [assetPage.assets]);

  const {
    data: telemetryData,
    loading: telemetryLoading,
    error: telemetryError,
  } = useLastMeasurementByTagMacs(tagMacs);

  const {buildingTree, loading: buildingTreeLoading} = useDrilldownData(
    rootID
  );

  useEffect(() => {
    setErrors(
      assetError !== undefined ||
      useCaseError !== undefined ||
      telemetryError !== undefined
    );
  }, [assetError, useCaseError, telemetryError]);

  const download = async () => {
    const token = await keycloak.getTokenSilently();
    const url = new URL(
      process.env.REACT_APP_REST_URI_DOWNLOAD_TEMPERATURE!,
      window.location.origin
    );
    url.searchParams.append("access_token", token);
    url.searchParams.append("useCaseId", useCaseData.useCase.id ?? "");
    if (filterCriteria !== undefined) {
      const {groupIds} = filterCriteria;
      if (groupIds !== undefined && groupIds !== null) {
        groupIds.forEach((group: string) => {
          url.searchParams.append("groupIds", group);
        });
      }
    }

    url.searchParams.append(
      "selectedMonth",
      dateExportMonth!.value.toISOString()
    );
    window.open(url.href, "_blank", "noopener");
  };

  const {
    _assetDelete,
    loading: deleteLoading,
    error,
    message,
  } = useAssetDelete(assetDeleteId, refetch);

  useEffect(() => {
    setLoading(
      assetLoading || useCaseLoading || telemetryLoading || deleteLoading
    );
  }, [assetLoading, useCaseLoading, telemetryLoading, deleteLoading]);

  useEffect(() => {
    if (error) {
      setAlertInput({
        show: true,
        color: "danger",
        message,
      });
    } else if (error === false) {
      setAlertInput({
        show: true,
        color: "success",
        message,
      });
      setTimeout(() => {
        setAlertInput((input) => ({...input, show: false}));
      }, 5000);
    }
  }, [error, message]);

  useEffect(() => {
    let mounted = true;

    const _onShowAlert = () => {
      setAlertShow(true);
      setTimeout(() => {
        setAlertShow(false);
        delete state.renderAlert;
        history.replace(state);
      }, alertTime);
    };

    if (state?.renderAlert && mounted) {
      _onShowAlert();
    }

    return () => {
      mounted = false;
    };
  }, [state, history]);

  const _cancelModal = () => {
    setAssetDeleteId("");
    setModalShow(false);
  };

  const _onAlertDismiss = () => {
    setAlertShow(false);
    const state = {...(history.location.state as any)};
    delete state.renderAlert;
    history.replace(state);
  };

  const _onDeleteDismiss = () => {
    setAlertInput({...alertInput, show: false});
  };

  const _toggleModal = () => {
    setShowDownloadForm(!showDownloadForm);
  };

  const handleDateExportSelect = (selectedOption: any) => {
    setDateExportMonth(selectedOption);
  };

  const _renderDownloadModal = () => {
    return (
      <Modal isOpen={showDownloadForm}>
        <div className="modal-header">
          <h5 className="modal-title">Excel Export</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => _toggleModal()}
          />
        </div>
        <ModalBody>
          Es werden die aktuellen gefilterten Temperaturdaten exportiert.
          <Select
            options={dateExportOptions()}
            placeholder={"Bitte wählen..."}
            onChange={handleDateExportSelect}
            value={dateExportMonth}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            outline
            onClick={() => {
              setShowDownloadForm(!showDownloadForm);
            }}
          >
            Abbrechen
          </Button>{" "}
          <div
            className={`btn btn-outline-secondary ${
              dateExportMonth?.value ? "" : "disabled-link"
            }`}
            onClick={() => {
              _toggleModal();
              download();
            }}
          >
            Exportieren
          </div>
        </ModalFooter>
      </Modal>
    );
  };

  const _generateActions = useCallback(
    (item) => {
      const onClickDelete = (assetId: string) => {
        setModalShow(true);
        setAssetDeleteId(assetId);
      };

      return [
        {
          text: "Details anzeigen",
          to: generatePath(
            ROUTES.home.childRoutes.assets.childRoutes.detailEdit.path,
            {id: item.id}
          ),
          role: readRole,
        },
        {
          text: "Bearbeiten",
          to: generatePath(
            ROUTES.home.childRoutes.assets.childRoutes.detailEdit.childRoutes
              .edit.path,
            {id: item.id}
          ),
          role: editRole,
        },
        {
          text: "Löschen",
          onClick: () => onClickDelete(item.id),
          role: deleteRole,
        },
      ];
    },
    [deleteRole, editRole, readRole]
  );
  return (
    <>
      {errors && (
        <Alert color="danger">Es ist ein Fehler beim Laden aufgetreten.</Alert>
      )}
      {loading && <Loading/>}
      {_renderDownloadModal()}
      <HyprosModal
        header="Löschen"
        body="Wollen Sie das Asset wirklich löschen?"
        show={showModal}
        acceptButtonColor="danger"
        acceptButtonText="Löschen"
        onConfirm={() => {
          setModalShow(false);
          _assetDelete();
        }}
        cancel={true}
        onCancel={_cancelModal}
      />
      {state ? (
        <Alert color="success" isOpen={alertShow}>
          {state.alertMessage}
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => _onAlertDismiss()}
          />
        </Alert>
      ) : null}
      <Alert color={alertInput.color} isOpen={alertInput.show}>
        {alertInput.message}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
          onClick={() => _onDeleteDismiss()}
        />
      </Alert>

      {useCaseData?.useCase?.availableGroups && (
        <div className="mb-1 d-flex justify-content-end align-items-baseline">
          <Button
            color="light"
            className="border-dark"
            onClick={() => {
              setShowDownloadForm(!showDownloadForm);
            }}
          >
            Exportieren
          </Button>
        </div>
      )}
      <AssetTrackingTemplate
        data={AssetService.mapTelemetryToAssets(
          assetPage,
          telemetryData?.getLastMeasurementByTagMacs
        )}
        pagination={pageCriteria}
        onItemSelect={setBuildingPart}
        setIPagination={setPagination}
        onLevelToggle={setRootID}
        onGenerateActions={_generateActions}
        buildingTreeLoading={buildingTreeLoading}
        name={name}
        tree={buildingTree}
        buildingPartIDExternal={filterCriteria?.buildingPartIDExternal}
        assetGroups={useCaseData?.useCase?.availableGroups}
        checkedGroups={filterCriteria?.groupIds ?? []}
        onClick={setGroupFilter}
      />
    </>
  );
};

export default TemperatureMonitoring;
