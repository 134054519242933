import React from "react";
import { FormFeedback, FormGroup, Input, InputProps, Label } from "reactstrap";
import "./BoundsInput.scss";

const BoundsInput: React.FC<InputProps> = ({ input, meta }) => {
    const { name, onChange } = input;
    const { error, submitError } = meta;
    const inputValue = input.value;
    
    const minChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const current = { upper: inputValue?.upper, lower: event.target.value };
        return onChange(current);
    }

    const maxChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const current = { upper: event.target.value, lower: inputValue?.lower };
        return onChange(current);
    }

    return (
        <div className="bounds-input">
            <FormGroup key={`bounds`} className="d-inline-flex">
                <Label>Minimum:&nbsp;</Label>
                <Input
                    type="number"
                    name={`${name}.lower`}
                    value={inputValue?.lower === undefined || inputValue?.lower == null ? "" : inputValue?.lower}
                    onChange={minChanged}
                />
                <Label>&nbsp;&deg;C</Label>
                <div className="mx-3"></div>
                <Label>Maximum:&nbsp;</Label>
                <Input
                    type="number"
                    name={`${name}.upper`}
                    value={inputValue?.upper === undefined || inputValue?.upper == null ? "" : inputValue?.upper}
                    onChange={maxChanged}
                />
                <Label>&nbsp;&deg;C</Label>
            </FormGroup>
            {(error || submitError) && <FormFeedback>{error || submitError}</FormFeedback>}
        </div>
    );
};

export default BoundsInput;