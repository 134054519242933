import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import _ from "lodash";
import { OptionsType } from "react-select";
import { RangeBounds } from "../models/assets/Asset";
import { IS_LEAF } from "../shared/queries/BuildingPartsQueries";

export const required = (value: any) => {
  const message = "Dieses Feld ist erforderlich.";
  if (Array.isArray(value)) {
    return value.length > 0 ? undefined : message;
  }
  return value ? undefined : message;
};

export const notEmpty = (value: any[]) =>
  Array.isArray(value) && value.length > 0
    ? undefined
    : "Bitte treffen Sie mindestens eine Auswahl.";

export const noScript = (value: string) =>
  !RegExp(/<(\/?\w+[^\n>]*\/?)>/gi).test(value)
    ? undefined
    : "Dieses Format ist nicht erlaubt.";

export const onlyNumbers = (input: any) =>
  isNaN(Number(input)) ? "Nur Zahlen sind erlaubt." : undefined;

export const macAddress = (input: any) =>
  !RegExp(/^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/).test(input)
    ? "Nur gültige Mac-Adressen sind erlaubt (XX:XX:XX:XX:XX:XX)."
    : undefined;

export const composeValidators = (...validators: any) => (value: any) =>
  validators.reduce(
    (error: string, validator: any) => error || validator(value),
    undefined
  );

export const validateBuildingPartLeaf = (
  client: ApolloClient<NormalizedCacheObject> | undefined
) => {
  const errorMessage = "Bitte bis zum letzten Gebäudeteil auswählen.";
  if (client === undefined) return undefined;
  return async (
    buildingPartId: OptionsType<{ label: string; value: string }>
  ) => {
    if (buildingPartId === null || buildingPartId === undefined)
      return undefined;
    const result = await client.query({
      query: IS_LEAF,
      variables: {
        buildingPartExternalId: _.last<{ label: string; value: string }>(
          buildingPartId
        )?.value,
      },
    });
    return result.data.isLeaf ? undefined : errorMessage;
  };
};

export const validateBounds = (value: RangeBounds) => {
  if (value === undefined || value == null) return undefined;
  if (value.lower == null || value.upper == null) return undefined;
  if (String(value.lower) === "" || String(value.upper) === "") return undefined;

  if (+value.lower >= +value.upper)
    return "Unterer Grenzwert muss unter dem oberen liegen.";

  return undefined;
}