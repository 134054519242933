import React from "react";
import { FieldRenderProps } from "react-final-form";
import { Input, InputProps } from "reactstrap";
import { InputType } from "reactstrap/lib/Input";

type Props<T extends string | number | string[]> = FieldRenderProps<T> &
  InputProps;

function InputField<T extends string | number | string[]>({
  input,
  meta,
  ...rest
}: Props<T>) {
  return (
    <Input
      {...input}
      {...rest}
      invalid={
        !!(
          !meta.dirtySinceLastSubmit &&
          meta.touched &&
          (meta.error || meta.submitError)
        )
      }
      type={input.type as InputType}
    />
  );
}

export default InputField;
