import { ApolloQueryResult } from "@apollo/client";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormApi, SubmissionErrors } from "final-form";
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Field, Form } from "react-final-form";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Button, Col, Form as ReactForm, Label, Row } from "reactstrap";
import {
  BoundsInput,
  BuildingpartSelectField,
  CheckboxGroup,
  InputField,
  TypeaheadField,
} from "../../forms";
import ErrorFeedback from "../../forms/components/ErrorFeedback";
import {
  composeValidators,
  noScript,
  notEmpty,
  required,
  validateBounds,
  validateBuildingPartLeaf,
} from "../../forms/validation";
import { useGqlClient } from "../../hooks";
import { Node } from "../../hooks/drilldownData";
import { AssetFormData } from "../../models/assets/Asset";
import TagManagerModal from "../components/TagManagerModal";
import GeoCordsModal, { MarkerProps } from "./GeoCordsModal";

const MAX_DESCRIPTION_CHARS = 255;

export const AssetForm: React.FC<IAssetFormProps> = ({
  onSubmit,
  initialValues,
  availableAssetGroups,
  availableUseCases,
  availableTags,
  beaconLabel,
  acceptButtonText,
  selectedBuildingparts,
  refetchAssetData,
}) => {
  const client = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_BUILDING_DATA!);
  const history = useHistory();
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const [modal, setModal] = useState(false);
  const [modalGeoCords, setModalGeoCords] = useState(false);
  const [geocords, setGeocords] = useState<MarkerProps | undefined>(undefined);
  const toggle = () => setModal(!modal);
  const formRef: MutableRefObject<
    FormApi<AssetFormData, Partial<AssetFormData>> | undefined
  > = useRef();

  const initValues = useMemo(() => {
    if (initialValues?.geocords !== geocords) {
      if (geocords) return { ...initialValues, geocords };
      return { ...initialValues, geocords: undefined };
    }
    return initialValues;
  }, [initialValues, geocords]);

  useEffect(() => {
    setGeocords(initialValues?.geocords);
  }, [initialValues]);

  useEffect(() => {
    if (geocords) {
      formRef.current?.change("geocords", geocords);
    }
  }, [geocords]);

  useEffect(() => {
    if (pathname.includes("tags")) {
      setModal(true);
    } else {
      setModal(false);
    }
  }, [pathname, setModal]);

  const handleClosed = useCallback(() => {
    history.push(url);
  }, [history, url]);

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
      >
        {({
          handleSubmit,
          pristine,
          form,
          submitting,
          values,
          invalid,
          modifiedSinceLastSubmit,
          hasValidationErrors,
          hasSubmitErrors,
        }) => {
          formRef.current = form;
          return (
            <ReactForm name="assetForm" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={2}>
                  Module: <span className="text-danger">*</span>
                </Col>
                <Col>
                  <Field
                    name="useCaseIds"
                    validate={notEmpty}
                    options={[...availableUseCases]
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((value: { name: string; id: string }) => {
                        return { label: value.name, value: value.id };
                      })}
                    component={CheckboxGroup}
                  />
                  <ErrorFeedback name="useCaseIds" />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={2}>
                  Gruppe: <span className="text-danger">*</span>
                </Col>
                <Col>
                  <Field
                    options={availableAssetGroups}
                    name="assetGroups"
                    id="assetGroups"
                    labelKey="name"
                    placeholder="Bitte wählen Sie..."
                    validate={required}
                    component={TypeaheadField}
                    multiple={true}
                  />
                  <ErrorFeedback name="assetGroups" />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={2}>
                  Bezeichnung: <span className="text-danger">*</span>
                </Col>
                <Col>
                  <Field
                    name="name"
                    type="text"
                    maxLength="80"
                    validate={composeValidators(required, noScript)}
                    component={InputField}
                  />
                  <ErrorFeedback name="name" />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={2}>Inventarnummer:</Col>
                <Col>
                  <Field
                    name="inventoryNumber"
                    type="text"
                    maxLength="80"
                    validate={noScript}
                    component={InputField}
                  />
                  <ErrorFeedback name="inventoryNumber" />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={2} className="d-inline-flex justify-content-between">
                  <span>
                    {beaconLabel} <span className="text-danger">*</span>
                  </span>
                  <Button
                    size="sm"
                    outline
                    onClick={() => history.push(`${url}/tags`)}
                  >
                    <FontAwesomeIcon icon="edit" />
                  </Button>
                </Col>
                <Col>
                  <Field
                    name="tags"
                    id="tags"
                    options={availableTags}
                    placeholder="Bitte wählen Sie..."
                    labelKey="name"
                    validate={required}
                    component={TypeaheadField}
                  />
                  <ErrorFeedback name="tags" />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={2}>Fester Standort:</Col>
                <Col>
                  <Field
                    name="buildingparts"
                    placeholder="Bitte wählen Sie..."
                    component={BuildingpartSelectField}
                    selectedBuildingparts={selectedBuildingparts}
                    validate={validateBuildingPartLeaf(client)}
                  />
                  <ErrorFeedback name="buildingparts" />
                </Col>
                {form.getFieldState("buildingparts")?.value &&
                  form.getFieldState("buildingparts")?.valid &&
                  !form.getFieldState("buildingparts")?.validating && (
                    <Col className="d-inline-flex">
                      <Button
                        size="sm"
                        outline
                        onClick={() => setModalGeoCords(true)}
                      >
                        <FontAwesomeIcon icon="map-marked-alt" />
                      </Button>
                      <Field
                        className="text-center me-1 ms-2"
                        name="geocords.latitude"
                        component={InputField}
                        placeholder="Latitude"
                        disabled
                        isEqual={() =>
                          initialValues?.geocords === values.geocords
                        }
                      />
                      <Field
                        className="text-center me-2 ms-1"
                        name="geocords.longitude"
                        component={InputField}
                        placeholder="Longitude"
                        disabled
                        isEqual={() =>
                          initialValues?.geocords === values.geocords
                        }
                      />
                      <Button
                        size="sm"
                        outline
                        onClick={() => {
                          form.change("geocords", undefined);
                        }}
                        disabled={!values.geocords}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                    </Col>
                  )}
              </Row>
              <Row className="mb-3">
                <Col md={2}>Temperaturgrenzen:</Col>
                <Col md={8}>
                  <Field
                    name="allowedTemperatureRange"
                    id="allowedTemperatureRange"
                    component={BoundsInput}
                    validate={validateBounds}
                  />
                </Col>
              </Row>
              <Row className="mb-1">
                <Col md={2}>Beschreibung:</Col>
                <Col className="d-flex flex-column">
                  <Field
                    name="description"
                    type="textarea"
                    id="description"
                    maxLength={MAX_DESCRIPTION_CHARS}
                    validate={noScript}
                    component={InputField}
                  />
                  <ErrorFeedback name="description" />
                  <Label className="ms-auto">
                    noch{" "}
                    {MAX_DESCRIPTION_CHARS - (values?.description?.length ?? 0)}{" "}
                    Zeichen
                  </Label>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={8} />
                <Col md={2}>
                  <Button
                    block
                    color="danger"
                    onClick={() => {
                      /* Workaround for reset while keepDirtyOnReinizialize is set to true.
                        Reset will no longer take the initial values like expected.
                        https://github.com/final-form/final-form/issues/151#issuecomment-425867172
                        */
                      form.setConfig("keepDirtyOnReinitialize", false);
                      form.reset();
                      form.setConfig("keepDirtyOnReinitialize", true);
                    }}
                    disabled={pristine || submitting}
                    style={{ minWidth: "100%" }}
                  >
                    Abbrechen
                  </Button>
                </Col>
                <Col md={2}>
                  <Button
                    block
                    color="success"
                    type="submit"
                    disabled={
                      (hasValidationErrors && hasSubmitErrors) ||
                      pristine ||
                      (invalid && !modifiedSinceLastSubmit)
                    }
                    style={{ minWidth: "100%" }}
                  >
                    {acceptButtonText}
                  </Button>
                </Col>
              </Row>
            </ReactForm>
          );
        }}
      </Form>
      <GeoCordsModal
        key={modalGeoCords ? "open" : "closed"}
        isOpen={modalGeoCords}
        onClosed={(data) => {
          setGeocords(data);
          setModalGeoCords(false);
        }}
        onToggle={() => setModalGeoCords(!modalGeoCords)}
        initialValues={initValues?.geocords}
      />
      <TagManagerModal
        isOpen={modal}
        onClosed={handleClosed}
        onToggle={toggle}
        tags={availableTags}
        refetchAssetData={refetchAssetData}
      />
    </>
  );
};

export interface IAssetFormProps {
  acceptButtonText: string;
  availableAssetGroups: { name: string; id: string }[];
  availableUseCases: { name: string; id: string }[];
  availableTags: { name: string; id: string }[];
  beaconLabel: string;
  onSubmit: (
    formValues: AssetFormData
  ) => Promise<SubmissionErrors | undefined>;
  initialValues: AssetFormData | undefined;
  selectedBuildingparts?: Node[];
  refetchAssetData?: () => Promise<ApolloQueryResult<any>>;
}

export default AssetForm;
