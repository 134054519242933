import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import useGqlClient from "../../hooks/useGqlClient";
import { AssetById } from "../../models/assets/Asset";
import { Position } from "../../models/Position";
import {
  ASSET_BY_ID,
  GET_UPDATE_ON_POSITION_BY_ID,
} from "../queries/assetQuery";

const useAsset = (id?: string, fetchPosition = true, query = ASSET_BY_ID) => {
  const assetClient = useGqlClient(
    process.env.REACT_APP_GRAPHQL_URI_ASSETS!,
    fetchPosition
      ? process.env.REACT_APP_GRAPHQL_WEBSOCKETURI_ASSETS!
      : undefined
  );

  const {
    data,
    loading,
    error,
    subscribeToMore,
    refetch,
  } = useQuery<AssetById>(query, {
    variables: {
      id,
    },
    client: assetClient,
    skip: !id,
  });

  useEffect(() => {
    if (fetchPosition) {
      return subscribeToMore<PositionResult>({
        onError: (error) => {
          console.error(error);
        },
        document: GET_UPDATE_ON_POSITION_BY_ID,
        variables: {
          assetId: id,
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData) return prev;
          const newPosition: Position = subscriptionData.data.positionById;

          return {
            asset:
              prev.asset !== null
                ? { ...prev.asset, lastPosition: newPosition }
                : null,
          };
        },
      });
    }
  }, [id, fetchPosition, subscribeToMore]);

  return { data: data?.asset, loading, error, refetch };
};

interface PositionResult {
  positionById: Position;
}

export default useAsset;
