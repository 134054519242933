import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from "react-pro-sidebar";
import { matchPath, NavLink, useLocation } from "react-router-dom";
import { useKeycloak } from "../auth";
import { rolePermission } from "../rolePermission";
import "./style/Navigation.scss";
import UserIcon from "./UserIcon";
import { ROUTES } from "../routes";

const Navigation: React.FC<INavigationProps> = ({
  collapsed,
  changeMenuState,
}) => {
  const location = useLocation();
  const keycloak = useKeycloak();

  const isActive = (path: string, role?: string) => {
    if (!(role === undefined || keycloak.hasResourceRole(role)))
      return "hidden";
    return matchPath(location.pathname, { path }) ? "active" : "";
  };

  return (
    <ProSidebar collapsed={collapsed} onToggle={() => changeMenuState()}>
      <SidebarHeader>
        <UserIcon />
      </SidebarHeader>
      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem
            className={isActive(ROUTES.home.childRoutes.useCases.path)}
            icon={<FontAwesomeIcon icon="laptop" />}
          >
            <NavLink to={ROUTES.home.childRoutes.useCases.path}>
              Übersicht
            </NavLink>
          </MenuItem>
          <MenuItem
            className={isActive(
              ROUTES.home.childRoutes.assets.childRoutes.create.path,
              rolePermission.ASSET_CREATE
            )}
            icon={<FontAwesomeIcon icon="mobile-alt" />}
          >
            <NavLink
              to={ROUTES.home.childRoutes.assets.childRoutes.create.path}
            >
              Asset erfassen
            </NavLink>
          </MenuItem>
          <MenuItem
            className={isActive(
              ROUTES.home.childRoutes.locations.path,
              rolePermission.LOCATION_READ
            )}
            icon={<FontAwesomeIcon icon="map-marked-alt" />}
          >
            <NavLink to={ROUTES.home.childRoutes.locations.path}>
              Standorte
            </NavLink>
          </MenuItem>
        </Menu>
      </SidebarContent>
      <SidebarFooter>
        <Menu>
          <MenuItem icon={<FontAwesomeIcon icon="sign-out-alt" />}>
            <NavLink to={ROUTES.home.childRoutes.logout.path}>Abmelden</NavLink>
          </MenuItem>
        </Menu>
      </SidebarFooter>
    </ProSidebar>
  );
};

interface INavigationProps {
  collapsed: boolean;
  changeMenuState(): void;
}

export default Navigation;
