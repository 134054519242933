import { Position2D } from "@deck.gl/core";
import { PickInfo, ViewStateProps } from "@deck.gl/core/lib/deck";
import { IconLayer, ScatterplotLayer } from "@deck.gl/layers";
import { IconMapping } from "@deck.gl/layers/icon-layer/icon-layer";
import DeckGL from "@deck.gl/react";
import * as React from "react";
import { useState } from "react";
import ReactMapGL, { Popup, ViewState } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import ICON_ATLAS from "../icon-atlas.png";
import {
  addLocator,
  selectActiveLocator,
  selectCurrentLevel,
  selectIsDragging,
  selectIsSatelliteViewActive,
  selectLocators,
  setIsDragging,
  toggleLocator,
  updateCoordinate,
} from "../locatorManagerSlice";
import { GROUPS } from "./LocatorForm";
import { Locator } from "./LocatorManager";
import MacAddressSearchField from "../../forms/components/SearchField";
import CuGeoJsonFloorplan from "../../catchupMaps/CuGeoJsonFloorplan";
import { useMacSearchField } from "../hooks/useMacSearchField";
import "../styles/MarkerOverlay.scss";

export const ICON_MAPPING: IconMapping = {
  [GROUPS.AcessPoint]: {
    x: 1,
    y: 1,
    width: 50,
    height: 50,
  },
  [GROUPS.AcessPoint + "_active"]: {
    x: 53,
    y: 1,
    width: 50,
    height: 50,
  },
  [GROUPS.Locator]: {
    x: 105,
    y: 1,
    width: 50,
    height: 50,
  },
  [GROUPS.Locator + "_active"]: {
    x: 157,
    y: 1,
    width: 50,
    height: 50,
  },
};

const MarkerOverlay: React.FC<MarkerOverlayProps> = ({
  children,
  draggable = true,
  showSignalRadius = false,
  initialViewport,
  onClick,
  iconAtlas = ICON_ATLAS,
  iconMapping = ICON_MAPPING,
}) => {
  const dispatch = useDispatch();
  const [viewport, setViewport] = React.useState(initialViewport);
  const [data, setData] = React.useState<LocatorMarker[]>([]);
  const [mapStyle, setMapStyle] = React.useState<string>(
    process.env.REACT_APP_MB_STYLE ?? ""
  );
  const [popupInfo, setPopupinfo] = useState<PickInfo<Locator> | null>(null);
  const activeLocator = useSelector(selectActiveLocator);
  const currentLevel = useSelector(selectCurrentLevel);
  const isDragging = useSelector(selectIsDragging);
  const locators = useSelector(selectLocators);
  const isSatelliteViewActive = useSelector(selectIsSatelliteViewActive);
  const macSearchFieldProps = useMacSearchField(setViewport);

  React.useEffect(() => {
    setData(
      locators.map((locator, index) => {
        return { ...locator, index };
      })
    );
  }, [locators, activeLocator.index, isSatelliteViewActive]);

  React.useEffect(() => {
    if (isSatelliteViewActive) {
      setMapStyle(process.env.REACT_APP_MB_STYLE_SATELLITE!!);
    } else {
      setMapStyle(process.env.REACT_APP_MB_STYLE!!);
    }
  }, [isSatelliteViewActive]);

  const _dragStart = () => {
    if (!draggable) return false;
    dispatch(setIsDragging(true));

    return true;
  };

  const _drag = (info: PickInfo<LocatorMarker>) => {
    if (!draggable) return false;
    const lngLat = info.coordinate as Position2D;
    if (lngLat === undefined) return false;

    const copy = [...data];
    copy[info.index].longitude = lngLat[0];
    copy[info.index].latitude = lngLat[1];
    setData(copy);

    return true;
  };

  const _dragEnd = (info: PickInfo<LocatorMarker>) => {
    if (!draggable) return false;

    dispatch(setIsDragging(false));
    const lngLat = info.coordinate as Position2D;

    if (lngLat === undefined) return false;
    dispatch(
      updateCoordinate({
        coordinate: { longitude: lngLat[0], latitude: lngLat[1] },
        index: info.index,
      })
    );
    // after drag end we need to update posiotions for hover
    // otherwise elements such as tooltips will be at position of drag start
    _onHover(info);

    return true;
  };

  const _onClick = (info: PickInfo<any>) => {
    if (info.object === null) {
      const { coordinate } = info as any;
      if (draggable)
        dispatch(
          addLocator({
            longitude: coordinate[0],
            latitude: coordinate[1],
            levelId: currentLevel?.id,
            layerId: currentLevel?.layerId,
          })
        );
    } else {
      _toggleActiveLocator(info);
    }

    if (onClick === undefined) return;

    onClick(info);
  };

  const _onHover = (info: PickInfo<LocatorMarker>) => {
    setPopupinfo(info);
  };

  const _isActive = (marker: LocatorMarker) =>
    marker.index === activeLocator.index;

  const _toggleActiveLocator = (info: PickInfo<LocatorMarker>) => {
    const { object } = info;

    if (object === null) return;

    const locator = { ...object };
    delete locator.index;

    dispatch(
      toggleLocator({
        locator: _isActive(object) ? null : locator,
        index: info.index,
      })
    );
  };

  const getIcon = (object: LocatorMarker) => {
    const icon = object.type ?? GROUPS.Locator;
    return _isActive(object) ? icon + "_active" : icon;
  };

  const _renderPopup = () => {
    if (isDragging || !popupInfo?.object) return null;
    const { name } = popupInfo.object;
    if (!name) return null;

    const lngLat: number[] = (popupInfo as any).coordinate as Position2D;

    return (
      <Popup longitude={lngLat[0]} latitude={lngLat[1]} closeButton={false}>
        {name}
      </Popup>
    );
  };

  const layers = [];

  if (showSignalRadius) {
    layers.push(
      new ScatterplotLayer<LocatorMarker>({
        id: "scatterplot-layer",
        data,
        opacity: 0.1,
        filled: true,
        getPosition: (d) => [d.longitude, d.latitude],
        getRadius: (d) => parseInt(d.radius ?? "0"),
        getFillColor: (_d) => [86, 152, 173],
      })
    );
  }

  layers.push(
    new IconLayer<LocatorMarker>({
      id: "icon-layer",
      data,
      pickable: true,
      onDragStart: _dragStart,
      onDrag: _drag,
      onDragEnd: _dragEnd,
      onHover: _onHover,
      iconAtlas,
      iconMapping,
      getIcon,
      sizeUnits: "meters",
      sizeScale: 2,
      getPosition: (d) => [d.longitude, d.latitude],
      updateTriggers: {
        getIcon: activeLocator.index,
      },
    })
  );

  return (
    <div onContextMenu={(evt) => evt.preventDefault()}>
      <div id="search-field" className="w-25 button-section">
        <MacAddressSearchField {...macSearchFieldProps} />
      </div>
      <DeckGL
        layers={layers}
        initialViewState={viewport as ViewStateProps}
        onClick={_onClick}
        controller={{
          dragPan: !isDragging,
          dragRotate: !isDragging,
        }}
      >
        <ReactMapGL
          {...viewport}
          key={mapStyle}
          mapboxAccessToken={process.env.REACT_APP_MB_ACCESS_TOKEN}
          mapStyle={mapStyle}
          onMove={(evt) => setViewport(evt.viewState)}
          reuseMaps
        >
          {currentLevel && (
            <CuGeoJsonFloorplan level={currentLevel.name} use3D />
          )}
          {_renderPopup()}
        </ReactMapGL>
      </DeckGL>
      {children}
    </div>
  );
};

export interface MarkerOverlayProps {
  children?: React.ReactNode;
  draggable?: boolean;
  showSignalRadius?: boolean;
  initialViewport: Partial<ViewState>;
  onClick?: <D>(info: PickInfo<D>) => void;
  iconMapping?: IconMapping;
  iconAtlas?: string;
}

type LocatorMarker = Locator & {
  index?: number;
};

export default MarkerOverlay;
