import moment from "moment";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IAlert } from "../../models/Alert";
import ActionButtons from "../../shared/ActionButtons";
import {
  DataTable,
  DataTableColum,
  DataTableStyleProps,
} from "../../shared/Tables/DataTable";
import { useAlarmApproval, useTlmAlarms } from "../hooks";
const AlarmList: React.FC<{}> = () => {
  const alarms = useTlmAlarms();
  const { isAlarmApproved, approveAlarm } = useAlarmApproval(alarms);

  const styles: DataTableStyleProps<IAlert> = {
    tableRowColor: (item: IAlert) => {
      return isAlarmApproved(item) ? "" : "table-danger";
    },
  };

  return (
    <>
      <DataTable
        rows={alarms}
        columns={renderHeading(approveAlarm)}
        styles={styles}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={false}
        closeButton={false}
        closeOnClick={false}
      />
    </>
  );
};

const renderHeading = (approveAlarm: (alarm: IAlert) => void) => {
  const dataTable: DataTableColum<IAlert>[] = [
    {
      key: "id",
      label: "TLM-Gerät",
    },
    {
      key: "patient.lastName",
      label: "Patient",
      valueAccessor: (item: IAlert) => (
        <span> {`${item.patient.firstName} ${item.patient.lastName}`}</span>
      ),
    },
    { key: "patient.gender", label: "Geschlecht" },
    {
      key: "timestamp",
      label: "Zeitstempel",
      valueFormatter: (item: string) =>
        moment(item).format("DD.MM.YYYY HH:mm:ss"),
    },
    { key: "description", label: "Alarm" },
    { key: "lastPosition.technicalRoomNumber", label: "Technische Raumnummer" },
    { key: "lastPosition.locatorName", label: "Letzter Standort" },
    {
      key: "actions",
      label: "Aktionen",
      valueAccessor: (item: IAlert) => (
        <ActionButtons
          actions={[
            {
              text: "Anzeigen",
              onClick: () => approveAlarm(item),
            },
          ]}
        />
      ),
    },
  ];
  return dataTable;
};

export default AlarmList;
