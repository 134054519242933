import React, { useEffect, useState } from "react";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";
import { Alert, Card, CardBody, CardHeader } from "reactstrap";
import { BeaconType, Tag } from "../../models/Tag";
import { ROUTES } from "../../routes";
import { Loading } from "../../shared";
import AssetTemplate from "../components/AssetTemplate";
import { useAssetCreateOrUpdate } from "../hooks/useAssetCreateOrUpdate";
import { useAssetFormData } from "../hooks/useAssetFormData";

function AssetEdit() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const match = useRouteMatch<{ id: string }>();
  const history = useHistory();

  const handleSuccess = () => {
    history.push(
      generatePath(ROUTES.home.childRoutes.assets.childRoutes.detailEdit.path, {
        id: match.params.id,
      })
    );
  };

  const {
    asset,
    assetGroups,
    availableTags,
    availableUseCases,
    errors: formDataErrors,
    loading: formDataLoading,
    selectedBuildingparts,
    refetchAssetData,
  } = useAssetFormData(match.params.id);

  const {
    createOrUpdate,
    loading: mutationLoading,
    errors: mutationErrors,
  } = useAssetCreateOrUpdate(handleSuccess, match.params.id);

  useEffect(() => {
    setLoading(formDataLoading || mutationLoading);
  }, [formDataLoading, mutationLoading]);

  useEffect(() => {
    setError(formDataErrors || mutationErrors);
  }, [formDataErrors, mutationErrors]);

  let beacon: Tag | undefined = undefined;
  const allAvailableTags = [...availableTags];
  if (asset) {
    for (const tag of asset.tags) {
      if (Object.values(BeaconType).includes(tag.beaconType)) {
        beacon = tag;

        if (!allAvailableTags.find((obj: any) => obj.id === tag.id)) {
          beacon!.isLocked = true;
          allAvailableTags.push(beacon);
        }
        break;
      }
    }
  }

  return (
    <>
      {loading && <Loading />}
      <Alert color="danger" isOpen={error}>
        Es ist ein unbekannter Fehler aufgetreten
      </Alert>
      <Card>
        <CardHeader>Asset bearbeiten</CardHeader>
        <CardBody>
          <AssetTemplate
            assetGroups={assetGroups}
            availableTags={allAvailableTags}
            availableUseCases={availableUseCases}
            acceptButtonText="Speichern"
            onAccept={createOrUpdate}
            selectedAssetName={asset?.name}
            selectedGroups={asset === undefined ? undefined : asset.assetGroups}
            selectedDescription={asset?.description}
            selectedBeacon={beacon}
            selectedBuildingparts={selectedBuildingparts}
            selectedUseCases={asset?.useCases}
            selectedInventoryNumber={asset?.inventoryNumber}
            selectedGeoCords={selectedBuildingparts ? asset?.lastPosition : []}
            selectedTemperatureRange={asset?.allowedTemperatureRange}
            beaconLabel="Selektierter Beacon"
            refetchAssetData={refetchAssetData}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default AssetEdit;
