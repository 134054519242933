import {faCompressAlt, faExpandAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import {ViewState} from "react-map-gl";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {AssetService, MultiAssetMap} from "../../asset";
import {IAlarm} from "../../models/Alarm";
import {Position} from "../../models/Position";
import {levelFields} from "../../__generated__/types";
import "../Alarm.scss";
import AlarmCard from "./AlarmCard";

const AlarmOverview: React.FC<IAlarmOverviewProps> = ({
                                                        data,
                                                        positions,
                                                        currentLevel,
                                                        locationName,
                                                        viewState,
                                                        audioError,
                                                      }) => {
  const [alarms, setAlarms] = useState(data);
  const [audioButton, setAudioButton] = useState<boolean>(audioError);
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const handleFullscreen = useFullScreenHandle();

  useEffect(() => {
    setAlarms(data);
  }, [data]);

  const _toggleFullscreen = () => {
    if (handleFullscreen.active) {
      setFullscreen(false);
      handleFullscreen.exit();
    } else {
      setFullscreen(true);
      handleFullscreen.enter();
    }
  };

  useEffect(() => {
    if (handleFullscreen.active) {
      setFullscreen(true);
    } else {
      setFullscreen(false);
    }
  }, [handleFullscreen.active]);

  const _renderCardContent = () => {
    if (!alarms.length)
      return <div className="p-2 text-muted">Keine Alarme vorhanden</div>;
    else
      return (
        <>
          {alarms
            .sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            )
            .filter((alarm) => alarm.deletedAt == null)
            .map((alarm: IAlarm, index) => (
              <AlarmCard key={index} alarm={alarm}/>
            ))}
        </>
      );
  };

  return (
    <>
      <div className="d-flex flex-row-reverse mb-1">
        <div
          className="btn btn-primary btn-sm ms-1"
          onClick={() => _toggleFullscreen()}
        >
          <FontAwesomeIcon icon={faExpandAlt}/>
        </div>
        <a
          href={process.env.REACT_APP_REST_URI_DOWNLOAD_ALARMS! + ""}
          target="_blank"
          className="btn btn-primary btn-sm ms-1"
          rel="noopener noreferrer"
        >
          Export Alarme
        </a>
        {audioButton ? (
          <div
            className="btn btn-primary btn-sm ms-1"
            onClick={() => {
              setAudioButton(false);
            }}
          >
            Sound aktivieren
          </div>
        ) : null}
      </div>
      <FullScreen handle={handleFullscreen}>
        <Row className={fullscreen ? "fullscreenOn" : ""}>
          <Col id="col-map">
            <MultiAssetMap
              assets={AssetService.mapPositionsToFeatureCollection(positions)}
              currentLevel={currentLevel}
              locationName={locationName}
              viewState={viewState}
              fullscreen={fullscreen}
            />
          </Col>
          <Col className="col-auto xl-5 ps-0">
            <Card id="alarmList" className="text-center">
              <CardHeader className="d-flex">
                <div>Notrufe</div>
                <div className="ms-auto">({alarms.length})</div>
                {fullscreen && (
                  <div
                    className="btn btn-primary btn-sm ms-auto"
                    onClick={() => _toggleFullscreen()}
                  >
                    <FontAwesomeIcon icon={faCompressAlt}/>
                  </div>
                )}
              </CardHeader>
              <CardBody className="p-0 overflow-auto">
                {_renderCardContent()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </FullScreen>
    </>
  );
};

export interface IAlarmOverviewProps {
  data: IAlarm[];
  positions: Position[];
  currentLevel: levelFields;
  availableLevels: levelFields[];
  locationName: string;
  viewState: ViewState;
  audioError: boolean;
}

export default AlarmOverview;
