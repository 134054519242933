import moment from "moment";
import React from "react";
import { IAlarm } from "../../models/Alarm";

const AlarmCard: React.FC<IAlarmCardProps> = ({ alarm }) => {
  return (
    <div
      className={`alarm-card-body bg-${
        alarm.confirmedAt ? "warning" : "danger"
      } m-2 p-2`}
    >
      <div className="text-dark">
        {moment(alarm.createdAt).format("DD.MM.YYYY - HH:mm:ss")} Uhr
      </div>
      {(alarm.lastPosition && (
        <div className="text-dark">{alarm.lastPosition.locatorName}</div>
      )) ?? <div className="text-dark">Standort unbekannt</div>}
    </div>
  );
};

interface IAlarmCardProps {
  alarm: IAlarm;
}

export default AlarmCard;
