import { useLazyQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useHistory } from "react-router-dom";
import { Form, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import useGqlClient from "../hooks/useGqlClient";
import { ISearchResult, searchQuery } from "./searchQuery";
import "./style/Header.scss";

export default function Search() {
  const history = useHistory();
  const typeahead = useRef<any>(null);
  const client = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_SEARCH!);

  const [runQuery, { data, loading }] = useLazyQuery<ISearchResult>(
    searchQuery,
    {
      client,
    }
  );

  const _handleOnChange = (selected: any[]) => {
    if (selected.length) {
      history.push(`/assets/${selected[0].id}`);
      typeahead.current?.clear();
    }
  };

  return (
    <Form className="w-50" id="search-form">
      <InputGroup
        id="search-input-group"
        className="w-100 shadow-sm rounded-pill overflow-hidden"
      >
        <AsyncTypeahead
          id="search-input"
          ref={typeahead}
          options={data?.search ?? []}
          useCache={false}
          isLoading={loading}
          positionFixed={true}
          labelKey="name"
          placeholder="Suchen..."
          emptyLabel="Ihre Suche ergab leider keinen Treffer."
          clearButton={true}
          onSearch={(value) => {
            runQuery({ variables: { input: value } });
          }}
          onChange={_handleOnChange}
        />
        <InputGroupAddon id="search-icon" addonType="append" className="xs-9">
          <InputGroupText>
            <FontAwesomeIcon icon="search" />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    </Form>
  );
}
