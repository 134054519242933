import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import useNavdata from "../hooks/breadCrumbData";
import "./styles/Breadcrumb.scss";

const BreadcrumbNavi: React.FC = () => {
  const data = useNavdata();
  const location = useLocation();

  return (
    <Breadcrumb id="breadcrumb" className="shadow-sm">
      {data.map(({ name, path }, key) =>
        key + 1 === data.length ? (
          <BreadcrumbItem active key={key}>
            {name}
          </BreadcrumbItem>
        ) : (
          <BreadcrumbItem key={key}>
            <Link to={{ pathname: path, state: location?.state }}>{name}</Link>
          </BreadcrumbItem>
        )
      )}
    </Breadcrumb>
  );
};

export default BreadcrumbNavi;
