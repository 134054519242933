import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "reactstrap/lib/Button";
import logo from "../logo.svg";
import BreadcrumbNavigation from "../shared/BreadcrumbNavigation";
import Search from "./Search";
import "./style/Header.scss";

export default function Header({ changeMenuState }: IHeaderProps) {
  const withSearch = process.env.REACT_APP_FEATURE_SEARCH === "true";

  return (
    <>
      <header className="Header d-flex align-items-center justify-content-between">
        <Button
          className="border-right"
          color="primary"
          id="menu-toggle"
          onClick={() => changeMenuState()}
          outline
        >
          <FontAwesomeIcon icon="bars" />
        </Button>
        {withSearch && <Search />}
        <img src={logo} width="150" height="70" className="p-1" alt="header" />
      </header>
      <BreadcrumbNavigation />
    </>
  );
}

interface IHeaderProps {
  changeMenuState(): void;
}
