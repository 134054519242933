import _ from "lodash";
import React from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Form as ReactForm, FormGroup, Label } from "reactstrap";
import { useBuildingpartParents } from "../../asset/hooks/useBuildingpartParents";
import { BuildingpartSelectField, InputField, SelectField } from "../../forms";
import ErrorFeedback from "../../forms/components/ErrorFeedback";
import {
  composeValidators,
  macAddress,
  noScript,
  onlyNumbers,
  required,
  validateBuildingPartLeaf
} from "../../forms/validation";
import useGqlClient from "../../hooks/useGqlClient";
import {
  removeLocator,
  selectIsLocked,
  updateLocator
} from "../locatorManagerSlice";
import { Locator } from "./LocatorManager";

export enum GROUPS {
  AcessPoint = "Access Point",
  Locator = "Hypros Locator",
}

const LocatorForm: React.FC<ILocatorFormProps> = ({ initialValues }) => {
  const dispatch = useDispatch();
  const locked = useSelector(selectIsLocked);

  const client = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_BUILDING_DATA!);

  const selectedBuildingparts = useBuildingpartParents(
    initialValues?.buildingPartIDExternal
  );

  const locator: Locator | undefined = initialValues
    ? {
        ...initialValues,
        buildingparts:
          initialValues.buildingparts ??
          selectedBuildingparts?.map((p) => {
            return {
              label: p.name,
              value: p.id,
            };
          }),
      }
    : undefined;

  const _onSubmit = (formValues: Locator) => {
    const locator: Locator = {
      ...formValues,
      buildingPartIDExternal: _.last(formValues.buildingparts)?.value,
    };
    delete locator.buildingparts;

    dispatch(updateLocator(locator));
  };

  if (!locator) {
    return (
      <Alert color="light" className="text-center shadow-sm rounded">
        Bitte wählen Sie einen Locator aus.
      </Alert>
    );
  }

  return (
    <Form initialValues={locator} onSubmit={_onSubmit}>
      {({
        handleSubmit,
        form,
        values,
        pristine,
        submitting,
        invalid,
        validating,
      }) => (
        <ReactForm onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="customerId">Kundenspezifische ID:</Label>
            <Field
              name="customerId"
              disabled={locked}
              component={InputField}
              validate={noScript}
            />
          </FormGroup>
          <FormGroup>
            <Label for="mac">
              Mac-Adresse: <span className="text-danger">*</span>
            </Label>
            <Field
              name="mac"
              disabled={locked}
              component={InputField}
              validate={composeValidators(noScript, macAddress, required)}
            />
            <ErrorFeedback name="mac" />
          </FormGroup>
          <FormGroup>
            <Label for="name">
              Rauminformation: <span className="text-danger">*</span>
            </Label>
            <Field
              name="name"
              disabled={locked}
              component={InputField}
              validate={composeValidators(noScript, required)}
            />
            <ErrorFeedback name="name" />
          </FormGroup>
          <FormGroup>
            <Label for="buildingparts">Fester Standort:</Label>
            <Field
              name="buildingparts"
              placeholder="Bitte wählen Sie..."
              component={BuildingpartSelectField}
              isDisabled={locked}
              validate={validateBuildingPartLeaf(client)}
              selectedBuildingparts={selectedBuildingparts}
            />
            <ErrorFeedback name="buildingparts" />
          </FormGroup>
          <FormGroup>
            <Label for="type">Typ:</Label>
            <Field
              name="type"
              placeholder=""
              options={Object.values(GROUPS).map((group) => {
                return { id: group, name: group };
              })}
              disabled={locked}
              component={SelectField}
            />
          </FormGroup>
          <FormGroup>
            <Label for="rssiCalibration">RSSI Kalibrierung:</Label>
            <Field
              name="rssiCalibration"
              disabled={locked}
              component={InputField}
              validate={composeValidators(noScript, onlyNumbers)}
            />
            <ErrorFeedback name="rssiCalibration" />
          </FormGroup>
          <FormGroup>
            <Label for="radius">Reichweite (Radius in Metern):</Label>
            <Field
              name="radius"
              disabled={locked}
              component={InputField}
              validate={composeValidators(noScript, onlyNumbers)}
            />
            <ErrorFeedback name="radius" />
          </FormGroup>

          <FormGroup>
            <Label for="longitude">Längengrad:</Label>
            <Field name="longitude" plaintext readOnly component={InputField} />
          </FormGroup>

          <FormGroup>
            <Label for="latitude">Breitengrad:</Label>
            <Field name="latitude" plaintext readOnly component={InputField} />
          </FormGroup>

          <div className="d-flex flex-column">
            <Button
              type="submit"
              color="success"
              disabled={
                pristine || locked || submitting || invalid || validating
              }
              block
            >
              Bestätigen
            </Button>
            <Button
              className="my-2"
              color="warning"
              disabled={pristine || locked}
              onClick={() => form.reset()}
              block
            >
              Abbrechen
            </Button>
            <Button
              color="danger"
              disabled={!initialValues || locked}
              onClick={() => dispatch(removeLocator())}
              block
            >
              Entfernen
            </Button>
          </div>
        </ReactForm>
      )}
    </Form>
  );
};

//type LocatorFormProps = InjectedFormProps<Locator>;
export interface ILocatorFormProps {
  initialValues: Locator | undefined;
}

export default LocatorForm;
