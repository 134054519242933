import React from "react";
import { RouteComponentProps } from "react-router";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { AlarmDetail, AlarmList } from "./alarms";
import { AssetCreate, AssetDetail, AssetEdit, AssetHistory } from "./asset";
import { Logout, useKeycloak } from "./auth";
import { LocationList, LocatorEdit } from "./location";
import { rolePermission } from "./rolePermission";
import ErrorDetail from "./shared/ErrorDetail";
import AlarmManagement from "./useCase/pages/AlarmManagement";
import AssetTracking from "./useCase/pages/AssetTracking";
import Pharmacy from "./useCase/pages/Pharmacy";
import TemperatureMonitoring from "./useCase/pages/TemperatureMonitoring";
import UseCaseList from "./useCase/UseCaseList";

export enum ErrorCodes {
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
}

export const SwitchWithSubRoutes = ({
  routes,
}: Record<string, SecureRouteProps>) => (
  <Switch>
    {Object.entries(routes).map((value, index) => (
      <SecureRouteWithSubRoutes key={index} {...value[1]} />
    ))}
  </Switch>
);

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
export const SecureRouteWithSubRoutes: React.FC<SecureRouteProps> = (
  route: SecureRouteProps
) => {
  const keycloak = useKeycloak();

  const authenticated = () => {
    return route.roles?.some((role) => {
      return !!keycloak.hasResourceRole(role);
    });
  };

  const render = route.roles === undefined || authenticated();

  if (!render) return <Redirect to={ROUTES.home.childRoutes.forbidden.path} />;
  return (
    <Route
      path={route.path}
      render={(props) => (
        <route.component {...props} routes={route.childRoutes as any} />
      )}
    />
  );
};

export type SecureRouteProps = RouteProps & {
  name?: string;
  component:
    | React.ComponentType<
        RouteComponentProps<any> & { routes: Record<string, SecureRouteProps> }
      >
    | React.ComponentType<any>;
  path: string;
  roles?: string[];
  childRoutes?: Record<string, SecureRouteProps>;
};

export type RoutesProps = {
  home: SecureRouteProps & {
    childRoutes: {
      alarmDetail: SecureRouteProps;
      assets: SecureRouteProps & {
        childRoutes: {
          create: SecureRouteProps;
          history: SecureRouteProps;
          detailEdit: SecureRouteProps & {
            childRoutes: {
              edit: SecureRouteProps;
              detail: SecureRouteProps;
            };
          };
        };
      };
      forbidden: SecureRouteProps;
      locations: SecureRouteProps & {
        childRoutes: {
          edit: SecureRouteProps;
        };
      };
      logout: SecureRouteProps;
      notFound: SecureRouteProps;
      useCases: SecureRouteProps & {
        childRoutes: {
          alarmManagement: SecureRouteProps;
          alarms: SecureRouteProps;
          assetTracking: SecureRouteProps;
          pharmacy: SecureRouteProps;
          temperatureMonitoring: SecureRouteProps;
          temperatureStationMonitoring: SecureRouteProps;
        };
      };
    };
  };
};

export const ROUTES: RoutesProps = {
  home: {
    component: () => <Redirect to="/use-cases" />,
    name: "Home",
    path: "/",
    childRoutes: {
      alarmDetail: {
        component: AlarmDetail,
        name: "Alarm Detailansicht",
        path: "/alarms/detail",
      },
      assets: {
        component: SwitchWithSubRoutes,
        path: "/assets",
        childRoutes: {
          create: {
            component: AssetCreate,
            name: "Asset erstellen",
            path: "/assets/create",
            roles: [rolePermission.ASSET_CREATE],
          },
          history: {
            component: AssetHistory,
            name: "Aufenthaltsverlauf",
            path: "/assets/:id/history",
          },
          detailEdit: {
            component: SwitchWithSubRoutes,
            path: "/assets/:id",
            name: "Asset Detailansicht",
            childRoutes: {
              edit: {
                component: AssetEdit,
                name: "Asset bearbeiten",
                path: "/assets/:id/edit",
                roles: [
                  rolePermission.ASSET_TRACKING_ASSET_EDIT,
                  rolePermission.TEMPERATURE_MONITORING_ASSET_EDIT,
                  rolePermission.TEMPERATURE_MONITORING_WARD_ASSET_EDIT,
                  rolePermission.PHARMACY_ASSET_EDIT,
                ],
              },
              detail: {
                component: AssetDetail,
                path: "/assets/:id",
                roles: [
                  rolePermission.ASSET_TRACKING_READ,
                  rolePermission.TEMPERATURE_MONITORING_READ,
                  rolePermission.TEMPERATURE_MONITORING_WARD_READ,
                  rolePermission.PHARMACY_READ,
                ],
              },
            },
          },
        },
      },
      forbidden: {
        component: () => <ErrorDetail errorCode={403} />,
        path: "/forbidden",
      },
      locations: {
        component: LocationList,
        name: "Standorte",
        path: "/locations",
        roles: [rolePermission.LOCATION_READ],
        childRoutes: {
          edit: {
            component: LocatorEdit,
            name: "Standort bearbeiten",
            path: "/locations/:id/edit",
            roles: [rolePermission.LOCATION_EDIT],
          },
        },
      },
      logout: {
        component: Logout,
        path: "/logout",
      },
      notFound: {
        component: () => <ErrorDetail errorCode={404} />,
        path: "/not-found",
      },
      useCases: {
        component: UseCaseList,
        name: "Module",
        path: "/use-cases",
        childRoutes: {
          alarmManagement: {
            component: AlarmManagement,
            name: "Alarm Management",
            path: "/use-cases/alarmManagement",
            roles: [rolePermission.ALARM_MANAGEMENT_READ],
          },
          alarms: {
            component: AlarmList,
            name: "Alarme",
            path: "/use-cases/alarms",
            roles: [rolePermission.ALARMS_READ],
          },
          assetTracking: {
            component: AssetTracking,
            name: "Asset Tracking",
            path: "/use-cases/asset-tracking",
            roles: [rolePermission.ASSET_TRACKING_READ],
          },
          pharmacy: {
            component: Pharmacy,
            name: "Apothekenkisten",
            path: "/use-cases/pharmacy",
            roles: [rolePermission.PHARMACY_READ],
          },
          temperatureMonitoring: {
            component: () => (
              <TemperatureMonitoring
                name="Temperaturüberwachung"
                editRole={rolePermission.TEMPERATURE_MONITORING_ASSET_EDIT}
                deleteRole={rolePermission.TEMPERATURE_MONITORING_ASSET_DELETE}
                readRole={rolePermission.TEMPERATURE_MONITORING_READ}
              />
            ),
            name: "Temperaturüberwachung",
            path: "/use-cases/temperature-monitoring",
            roles: [rolePermission.TEMPERATURE_MONITORING_READ],
          },
          temperatureStationMonitoring: {
            component: () => (
              <TemperatureMonitoring
                name="Temperaturüberwachung Station"
                editRole={rolePermission.TEMPERATURE_MONITORING_WARD_ASSET_EDIT}
                deleteRole={
                  rolePermission.TEMPERATURE_MONITORING_WARD_ASSET_DELETE
                }
                readRole={rolePermission.TEMPERATURE_MONITORING_WARD_READ}
              />
            ),
            name: "Temperaturüberwachung Station",
            path: "/use-cases/temperatureStation-monitoring",
            roles: [rolePermission.TEMPERATURE_MONITORING_WARD_READ],
          },
        },
      },
    },
  },
};

export const findRoute = (
  path: string,
  childRoutes: Record<string, SecureRouteProps> = ROUTES.home.childRoutes
): SecureRouteProps | undefined => {
  for (const route of Object.values(childRoutes)) {
    if (path.startsWith(route.path)) {
      if (path === route.path) return route;
      return findRoute(path, route.childRoutes);
    }
  }
  return undefined;
};
