import { ApolloQueryResult } from "@apollo/client";
import { SubmissionErrors } from "final-form";
import _ from "lodash";
import React, { memo, useMemo } from "react";
import { AssetFormData, RangeBounds } from "../../models/assets/Asset";
import { Position } from "../../models/Position";
import AssetForm from "./AssetForm";
import { MarkerProps } from "./GeoCordsModal";

const AssetTemplate: React.FC<IAssetTemplateProps> = ({
  assetGroups,
  availableTags,
  availableUseCases,
  onAccept,
  acceptButtonText,
  beaconLabel,
  selectedGroups,
  selectedDescription,
  selectedAssetName,
  selectedBeacon,
  selectedBuildingparts,
  selectedUseCases,
  selectedInventoryNumber,
  selectedGeoCords,
  selectedTemperatureRange,
  refetchAssetData,
}) => {
  const memoizedValues: AssetFormData | undefined = useMemo(() => {
    if (selectedGroups === undefined) return undefined;
    if (selectedAssetName === undefined) return undefined;
    if (selectedBeacon === undefined) return undefined;
    if (selectedUseCases === undefined) return undefined;
    if (selectedInventoryNumber === undefined) return undefined;

    const tags = mapIdToArray(availableTags, selectedBeacon);

    return {
      assetGroups: selectedGroups,
      description: selectedDescription ?? "",
      name: selectedAssetName,
      inventoryNumber: selectedInventoryNumber,
      tags: tags,
      useCaseIds: selectedUseCases.map((useCase) => useCase.id),
      buildingparts: selectedBuildingparts?.map((bp) => {
        return {
          label: bp.name,
          value: bp.id,
        };
      }),
      geocords: selectedGeoCords && {
        latitude: selectedGeoCords.latitude,
        longitude: selectedGeoCords.longitude,
        levelId: selectedGeoCords.levelId,
        locationId: selectedGeoCords.locationId,
      },
      allowedTemperatureRange: selectedTemperatureRange,
    };
  }, [
    availableTags,
    selectedBuildingparts,
    selectedGroups,
    selectedDescription,
    selectedAssetName,
    selectedBeacon,
    selectedUseCases,
    selectedInventoryNumber,
    selectedGeoCords,
    selectedTemperatureRange,
  ]);

  const handleSubmit = async (formValues: AssetFormData) => {
    const {
      useCaseIds,
      name,
      assetGroups,
      tags,
      description,
      buildingparts,
      inventoryNumber,
      geocords,
      allowedTemperatureRange,
    } = formValues;

    const assetGroupId: string[] = assetGroups.map((value) => value.id);
    const tagIds = tags.map((tag) => tag.id);
    const buildingPartIDExternal =
      Array.isArray(buildingparts) && buildingparts.length > 0
        ? _.last(buildingparts).value
        : null;

    return await onAccept(
      useCaseIds,
      name,
      assetGroupId,
      tagIds,
      description ?? "",
      buildingPartIDExternal,
      inventoryNumber,
      geocords,
      allowedTemperatureRange
    );
  };

  return (
    <AssetForm
      onSubmit={handleSubmit}
      availableAssetGroups={assetGroups}
      availableTags={availableTags}
      availableUseCases={availableUseCases}
      acceptButtonText={acceptButtonText}
      beaconLabel={beaconLabel}
      initialValues={memoizedValues}
      selectedBuildingparts={selectedBuildingparts}
      refetchAssetData={refetchAssetData}
    />
  );
};

const mapIdToArray: <T extends { id: string }>(
  allItems: T[],
  current: T
) => T[] = (allItems, current) => {
  const array: any[] = [];
  const item = allItems.find((item) => item.id === current.id);
  if (item !== undefined) {
    array.push(item);
  }

  return array;
};

interface IAssetTemplateProps {
  assetGroups: { name: string; id: string }[];
  availableUseCases: { name: string; id: string }[];
  availableTags: { name: string; id: string }[];
  acceptButtonText: string;
  onAccept(
    useCaseIds: string[],
    assetName: string,
    assetGroupIds: string[],
    tagId: string[],
    description: string,
    buildingPartIDExternal: string | null,
    inventoryNumber?: string,
    geocords?: MarkerProps | undefined,
    allowedTemperatureRange?: RangeBounds | undefined,
    temperatureMax?: number | undefined
  ): Promise<SubmissionErrors | undefined>;
  selectedGroups?: { id: string; name: string }[];
  selectedBeacon?: { name: string; id: string };
  selectedBuildingparts?: { id: string; name: string }[];
  selectedDescription?: string;
  selectedAssetName?: string;
  selectedUseCases?: { name: string; id: string }[];
  selectedInventoryNumber?: string;
  selectedGeoCords?: Position;
  selectedTemperatureRange?: RangeBounds | undefined;
  beaconLabel: string;
  refetchAssetData?: () => Promise<ApolloQueryResult<any>>;
}

export default memo(AssetTemplate);
