import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";
import AlarmOverview from "../../alarms/components/AlarmOverview";
import { useAllPositions } from "../../hooks/useAllPositions";
import { useLocations } from "../../location/hooks/useLocations";
import { Loading } from "../../shared";
import { useAlarms } from "../hooks/useAlarms";
import { useLevel } from "../hooks/useLevel";

const audio: HTMLAudioElement = new Audio(
  process.env.PUBLIC_URL + "/alarm.mp3"
);

const AlarmManagement: React.FC<{}> = () => {
  const [audioError, setAudioError] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const { locationData, locationError, locationLoading } = useLocations();
  const level = useLevel(locationData);

  const {
    data: positionData,
    loading: positionLoading,
    error: positionError,
  } = useAllPositions();

  const { alarms, positions: alarmPositions } = useAlarms();

  useEffect(() => {
    setLoading(positionLoading || locationLoading);
  }, [positionLoading, locationLoading]);

  useEffect(() => {
    setError(positionError !== undefined || locationError !== undefined);
  }, [positionError, locationError]);

  useEffect(() => {
    audio.autoplay = true; //https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
    audio.loop = true;
    audio.muted = true;
    return () => {
      audio.muted = true;
      audio.pause();
    };
    // eslint-disable-next-line
  }, []);

  if (alarms.length > 0) {
    if (
      alarms.filter((alarms) => alarms.confirmedAt !== null).length !==
      alarms.length
    ) {
      audio.muted = false;
      var promise = audio.play();
      promise.then((_) => {}).catch((_) => setAudioError(true));
    } else {
      audio.pause();
      audio.muted = true;
    }
  }

  if (!level) return <Alert color="warning">Keine Ebenen verfügbar.</Alert>;

  return (
    <>
      {loading && <Loading />}
      {error && (
        <Alert color="danger">Es ist ein Fehler beim Laden aufgetreten.</Alert>
      )}
      {locationData == null ? (
        <Alert color="warning">Standort leider nicht gefunden.</Alert>
      ) : (
        <AlarmOverview
          data={alarms}
          positions={
            alarmPositions.length > 0
              ? [...alarmPositions]
              : [...(positionData?.positions ?? [])]
          }
          currentLevel={level}
          availableLevels={locationData[0].availableLevels}
          locationName={locationData[0].name}
          viewState={locationData[0].viewport}
          audioError={audioError}
        />
      )}
    </>
  );
};

export default AlarmManagement;
