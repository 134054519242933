import _ from "lodash";
import React from "react";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import "./DataTable.scss";

export function DataTable<T extends { id: string }>({
  columns,
  rows,
  additionalHeader,
  styles,
}: DataTableProps<T>) {
  return (
    <Card className="DataTable">
      {additionalHeader && <CardHeader>{additionalHeader}</CardHeader>}
      <CardBody>
        <Table striped>
          <Head columns={columns} />
          <tbody>
            {rows.map((row) => (
              <Row key={row.id} item={row} columns={columns} styles={styles} />
            ))}
          </tbody>
        </Table>
        {rows.length <= 0 && (
          <p className="text-muted text-center pt-3">
            Keine Elemente vorhanden.
          </p>
        )}
      </CardBody>
    </Card>
  );
}

const Head: React.FC<HeadProps> = ({ columns }) => {
  return (
    <thead>
      <tr>
        {columns.map((column) => {
          if (column.hidden === true) return null;
          return (
            <th
              className={column.classNames?.join(" ") ?? ""}
              key={String(column.key)}
              onClick={() => {
                if (!_.isFunction(column.onClick)) return;
                column.onClick();
              }}
            >
              {column.label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

function Row<T>({ item, columns, styles }: RowProps<T>) {
  const _getValue = (column: DataTableColum<T>) => {
    let value;
    if (column.valueAccessor === undefined) {
      value = _.get(item, column.key);
    } else {
      value = column.valueAccessor(item);
    }

    if (value === undefined || value === null) {
      if (column.missingCellOverride !== undefined) {
        value = column.missingCellOverride;
      } else {
        value = <span className="text-muted">Unbekannt</span>;
      }
    } else if (column.valueFormatter !== undefined) {
      value = column.valueFormatter(value);
    }

    return value;
  };

  return (
    <tr
      className={
        styles?.tableRowColor !== undefined ? styles?.tableRowColor(item) : ""
      }
    >
      {columns.map((column) => {
        if (column.hidden === true) return null;
        return (
          <td
            key={String(column.key)}
            className={column.classNames?.join(" ") ?? ""}
          >
            {_getValue(column)}
          </td>
        );
      })}
    </tr>
  );
}

export interface DataTableProps<T> {
  columns: DataTableColum<T>[];
  rows: T[];
  additionalHeader?: React.ReactNode;
  styles?: DataTableStyleProps<T>;
}

export interface DataTableColum<T> {
  classNames?: string[];
  hidden?: boolean;
  key: keyof T | string;
  label: React.ReactNode;
  missingCellOverride?: React.ReactNode;
  onClick?: () => void;
  valueAccessor?: (item: T) => React.ReactNode;
  valueFormatter?: (value: any) => React.ReactNode;
}

export interface DataTableStyleProps<T> {
  tableRowColor?: (item: T) => string;
}

interface RowProps<T> {
  item: T;
  columns: DataTableColum<T>[];
  styles?: DataTableStyleProps<T>;
}

interface HeadProps {
  columns: HeadColumnProps[];
}

type HeadColumnProps = Omit<
  Override<DataTableColum<{}>, { key: string | number | symbol }>,
  "valueAccessor"
>;

type Override<T1, T2> = Omit<T1, keyof T2> & T2;
