import moment from "moment";
import React from "react";
import { Button } from "reactstrap";
import { IAlert } from "../../models/Alert";
import "../Alarm.scss";

export interface IAlarmToastContent {
  alarm: IAlert;
  onApprove(alarm: IAlert): void;
}

class AlarmToastContent extends React.Component<IAlarmToastContent> {
  render() {
    return (
      <>
        ALARM
        <br />
        <div className={"margin-5-20"}>
          {this.props.alarm.patient.firstName}{" "}
          {this.props.alarm.patient.lastName}
          <br />
          {this.props.alarm.description}
          <br />
          {moment(this.props.alarm.timestamp).format("DD.MM.YY HH:mm:ss")}
          <br />
          Standort: {this.props.alarm.lastPosition?.locatorName ?? "Unbekannt"}
          <br />
        </div>
        <Button
          outline
          className={"alarm-btn"}
          size={"sm"}
          block
          onClick={() => this.props.onApprove(this.props.alarm)}
        >
          Anzeigen
        </Button>
      </>
    );
  }
}

export default AlarmToastContent;
