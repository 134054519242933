import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from "@apollo/client";
import { useState } from "react";
import { deleteAssetQuery } from "../queries/assetDeleteQueries";
import { useGqlClient } from "../../hooks";
import { AssetFilterResult } from "../../hooks/useAssets";

export default function useAssetDelete(
  id: string,
  refetch?: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<AssetFilterResult>>
) {
  const assetsClient = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_ASSETS!);
  const [deleteAsset] = useMutation(deleteAssetQuery, { client: assetsClient });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [message, setMessage] = useState<string>("");

  const _assetDelete = async () => {
    setLoading(true);
    try {
      const { errors } = await deleteAsset({
        variables: { id: id },
      });
      if (errors) {
        setError(true);
        setMessage("Es ist ein Fehler beim Löschen des Assets aufgetreten");
      } else {
        setError(false);
        if (refetch !== undefined) await refetch();

        setMessage("Das Asset wurde erfolgreich gelöscht");
        setLoading(false);
      }
    } catch (errors) {
      setError(true);
      setMessage("Es ist ein Fehler beim Löschen des Assets aufgetreten");
    } finally {
      setError(null);
    }
  };

  return { _assetDelete, loading, error, message };
}
