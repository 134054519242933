import { useQuery } from "@apollo/client";
import moment from "moment";
import { useEffect } from "react";
import useGqlClient from "../../hooks/useGqlClient";
import {
  GET_MEASUREMENTS_BY_RANGE,
  GET_UPDATE_ON_MEASUREMENTS_BY_RANGE,
} from "../queries/AssetHistoryQueries";

const useMeasurementsByRange = (
  tagMac: string | undefined,
  start: string,
  end: string,
  selectedFunction: string,
  selectedGroupBy: string
) => {
  const historyClient = useGqlClient(
    process.env.REACT_APP_GRAPHQL_URI_HISTORY!,
    process.env.REACT_APP_GRAPHQL_WEBSOCKETURI_HISTORY
  );

  const { data, loading, error, subscribeToMore } = useQuery(
    GET_MEASUREMENTS_BY_RANGE,
    {
      skip: !tagMac,
      variables: {
        tagMac,
        start: start,
        end: end,
        function: selectedFunction,
        groupBy: selectedGroupBy,
      },
      client: historyClient,
    }
  );

  useEffect(() => {
    if (moment().isSame(start, "day") && moment().isSame(end, "day")) {
      return subscribeToMore({
        onError: (error) => {
          console.debug(error);
        },
        document: GET_UPDATE_ON_MEASUREMENTS_BY_RANGE,
        variables: {
          tagMac,
          start: start,
          end: end,
          function: selectedFunction,
          groupBy: selectedGroupBy,
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData) return prev;
          return Object.assign({
            getMeasurementsByRange: [
              ...subscriptionData.data.getUpdateOnMeasurementsByRange,
            ],
          });
        },
      });
    }
  }, [subscribeToMore, tagMac, start, end, selectedFunction, selectedGroupBy]);

  return { data, loading, error };
};

export default useMeasurementsByRange;
