import React, {useCallback} from "react";
import {Col, Row} from "reactstrap";
import AssetGroupFilter from "../../asset/components/AssetGroupFilter";
import {AssetPage, IAsset} from "../../models/assets/Asset";
import {AssetGroup} from "../../models/assets/AssetGroup";
import {Action} from "../../shared/ActionButtons";
import DrilldownFilter, {TransformedNode,} from "../../shared/drilldown-filter/DrilldownFilter";
import AssetListData from "./AssetListData";
import {PageCriteria} from "../../models/PageCriteria";
import Pagination from "../../shared/Pagination";

const AssetTrackingTemplate: React.FC<IAssetTrackingTemplate> = ({
                                                                   data,
                                                                   pagination,
                                                                   onItemSelect,
                                                                   setIPagination,
                                                                   onLevelToggle,
                                                                   onGenerateActions,
                                                                   buildingTreeLoading,
                                                                   name,
                                                                   tree,
                                                                   buildingPartIDExternal,
                                                                   assetGroups,
                                                                   checkedGroups,
                                                                   onClick,
                                                                 }) => {
  const {assets, assetCount, totalPages} = {...data};
  const handleItemSelect = useCallback(
    (id: string) => {
      onItemSelect(id);
    },
    [onItemSelect]
  );

  const handleLevelToggle = useCallback(
    (id?: string) => {
      onLevelToggle(id);
    },
    [onLevelToggle]
  );
  return (
    <Row className="pb-2">
      {(tree.length > 0 || (assetGroups !== undefined && assetGroups.length > 0)) &&
        <Col xs="auto">
          {tree.length > 0 && (
            <DrilldownFilter
              data={tree}
              onItemSelect={handleItemSelect}
              onLevelToggle={handleLevelToggle}
              selectedItem={buildingPartIDExternal ?? ""}
              loading={buildingTreeLoading}
              className="mb-3"
            ></DrilldownFilter>
          )}
          {assetGroups && checkedGroups && onClick && (
            <AssetGroupFilter
              assetGroups={assetGroups}
              defaultChecked={checkedGroups}
              onClick={onClick}
            />
          )}
        </Col>
      }
      <Col>
        {assets && (
          <>
            <AssetListData
              data={assets}
              name={name}
              setIPagination={setIPagination}
              iPagination={pagination}
              assetCount={assetCount ?? 0}
              onGenerateActions={onGenerateActions}
            />
            {totalPages && pagination ? (
              <div className="position-relative">
                <Pagination
                  pageCount={totalPages}
                  setIPagination={setIPagination}
                  iPagination={pagination}
                />
              </div>
            ) : null}
          </>
        )}
      </Col>
    </Row>
  );
};

interface IAssetTrackingTemplate {
  data: AssetPage | undefined;
  tree: TransformedNode[];
  name: string;
  pagination: PageCriteria;

  setIPagination(pagination: PageCriteria): void;

  onLevelToggle(id?: string): void;

  onItemSelect(id: string): void;

  onGenerateActions?: (item: IAsset) => Action[];
  buildingTreeLoading: boolean;
  buildingPartIDExternal: string | undefined;
  assetGroups?: AssetGroup[];
  checkedGroups?: string[] | null;

  onClick?(groupIds: string[]): void;
}

export default AssetTrackingTemplate;
